<div class="spinner-container" *ngIf="loading">
  <mat-spinner class="spinner"></mat-spinner>
</div>

<div class="container" [ngClass]="{'hidden':loading}">
  <form [formGroup]="childrenForm">
    <div class="filter-container mat-body">
      <mat-form-field appearance="fill">
        <mat-label>Creche</mat-label>
        <mat-select (selectionChange)="getSections($event.value)" formControlName="creche" #crecheDropdown>
          <mat-option>
            <ngx-mat-select-search [formControl]="crecheFilterCtrl" placeholderLabel="Creche..."></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="">--</mat-option>
          <mat-option *ngFor="let creche of filteredCreches | async" [value]="creche.id">{{creche.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="sections?.length > 0">
        <mat-label>Section</mat-label>
        <mat-select formControlName="section">
          <mat-option>
            <ngx-mat-select-search [formControl]="sectionFilterCtrl" placeholderLabel="Section..."></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="">--</mat-option>
          <mat-option *ngFor="let section of filteredSections | async" [value]="section.name">{{section.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div #childrenFormContainer formArrayName="children" id="children-container" *ngIf="this.childrenForm.get('section').valid">
      <div #formRow *ngFor="let child of children.controls; let childIndex=index" [formGroupName]="childIndex">

        <div>
          <!-- FIRST NAME -->
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>First name</mat-label>
            <input formControlName="firstName" matInput required class="firstname" />
            <mat-error *ngIf="child.controls.firstName.touched && child.controls.firstName.invalid">
              <span *ngIf="child.controls.firstName.errors.required">This field is mandatory.</span>
            </mat-error>
          </mat-form-field>

          <!-- LAST NAME -->
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Last name</mat-label>
            <input formControlName="lastName" matInput required class="lastname" />
            <mat-error *ngIf="child.controls.lastName.touched && child.controls.lastName.invalid">
              <span *ngIf="child.controls.lastName.errors.required">This field is mandatory.</span>
            </mat-error>
          </mat-form-field>

          <button #add mat-icon-button color="accent" [disabled]="!child.valid" class="add-child" (click)="addRow()">
            <mat-icon>add</mat-icon>
          </button>
        </div>

      </div>
    </div>
  </form>

  <button mat-raised-button color="primary" (click)="createChildren()" [disabled]="!childrenForm.valid" type="submit">Create</button>
  <button mat-stroked-button routerDirection="back" routerLink="/users" class="cancel-button">Cancel</button>
</div>
