<div id="sections-toggles" *ngIf="creche?.sections.length > 0; else noSection">
  <mat-button-toggle-group [multiple]="true" [disabled]="(loading | async)">
    <mat-button-toggle (change)="sectionChanged($event, true)" value="all" checked>All</mat-button-toggle>
    <mat-button-toggle (change)="sectionChanged($event)" *ngFor="let section of creche?.sections" value="{{section.name}}" checked>{{section.name}}</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<ng-template #noSection>
  No section defined for this creche
</ng-template>

<div id="children" *ngIf="creche?.sections.length > 0">
  <div id="authorized-children">
    <mat-card>
      <mat-card-title>Authorized children <span>({{authorizedChildrenDatasource.data.length}})</span></mat-card-title>
      <mat-card-content>
        <mat-spinner *ngIf="(loading | async)"></mat-spinner>
        <table mat-table
               matSort
               [ngClass]="{'hidden': (loading | async)}"
               [dataSource]="authorizedChildrenDatasource"
               [matSortDisableClear]="true"
               [matSortDirection]="'desc'" [matSortActive]="'tagCount'">
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Firstname</th>
            <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lastname</th>
            <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
          </ng-container>

          <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Section</th>
            <td mat-cell *matCellDef="let element">{{element.section}}</td>
          </ng-container>

          <ng-container matColumnDef="tagCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [start]="'desc'">Tag count</th>
            <td mat-cell *matCellDef="let element">{{element.tagCount}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="authorizedChildrenColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: authorizedChildrenColumns;">
        </table>
      </mat-card-content>
    </mat-card>
  </div>

  <div id="blocked-children">
    <mat-card>
      <mat-card-title>Blocked children <span>({{blockedChildrenDatasource.data.length}})</span></mat-card-title>
      <mat-card-content>
        <mat-spinner *ngIf="(loading | async)"></mat-spinner>
        <table mat-table
               matSort
               [ngClass]="{'hidden': (loading | async)}"
               [dataSource]="blockedChildrenDatasource"
               [matSortDisableClear]="true"
               [matSortDirection]="'asc'"
               [matSortActive]="'firstName'">
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [start]="'asc'">Firstname</th>
            <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lastname</th>
            <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
          </ng-container>

          <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Section</th>
            <td mat-cell *matCellDef="let element">{{element.section}}</td>
          </ng-container>

          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Child code</th>
            <td mat-cell *matCellDef="let element">
              <div class="nano-container">
                <div>{{element.nanoid}}</div>
                <button *ngIf="element.nanoid" mat-icon-button (click)="copyToClipboard($event, element.nanoid)"><mat-icon>content_copy</mat-icon></button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="blockedChildrenColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: blockedChildrenColumns;">
        </table>
      </mat-card-content>
    </mat-card>
  </div>

</div>
