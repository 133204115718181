import { createSelector } from '@ngrx/store';
import { ClientsCountersState, CountersState, selectCountersState } from '../counter.state';

export const selectClientsCountersState = createSelector(
  selectCountersState,
  (state: CountersState) => state.clientsCountersState
);

export const selectClientsCountersLoading = createSelector(
  selectClientsCountersState,
  (state: ClientsCountersState) => state?.loading
);

export const selectClientsCounters = createSelector(
  selectClientsCountersState,
  (state: ClientsCountersState) => state?.clientsCounters
);
