import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { combineLatest, Observable } from 'rxjs';
import { PhotoModel } from './model/photo.model';
import { chunkArray } from './utils';
import * as firebase from 'firebase';
import { map, switchMap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor(private firestore: AngularFirestore) {}

  getPhotos(photoIds: string[]): Observable<PhotoModel[]> {
    const idsByChunk = chunkArray<string>([...photoIds], 10);
    return combineLatest(
      idsByChunk.map(ids => this.firestore.collection<PhotoModel>('photos', ref => ref.where(firebase.firestore.FieldPath.documentId(), 'in', ids)).get())
    ).pipe(
      switchMap(querySnapshots => querySnapshots),
      map(querySnapshot => querySnapshot.docs),
      switchMap(docs => docs),
      map(doc => PhotoService.fromQueryDoc(doc)),
      toArray()
    );
  }

  private static fromQueryDoc(doc: QueryDocumentSnapshot<DocumentData>): PhotoModel {
    const photo = doc.data();
    return new PhotoModel(photo.publisher, photo.publishedAt, photo.tags);
  }
}
