export class PhotoModel {
  /** First name and last name of the published */
  publisher: string;
  /** Date a which the photo has been published */
  publishedAt: number;
  /** UIDs of the children tagged in the photo */
  tags: Array<string>;

  constructor(publisher: string, publishedAt: number, tags: Array<string>) {
    this.publisher = publisher;
    this.publishedAt = publishedAt;
    this.tags = tags;
  }
}
