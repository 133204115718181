import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Role } from '../../../../../functions/src/models/role.enum';
import { forkJoin, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { concatAll, flatMap, map, reduce, takeUntil, tap } from 'rxjs/operators';
import { Creche } from '../../../../../functions/src/models/creche';
import { AngularFirestore } from '@angular/fire/firestore';
import { deepCopy, enumSelector } from '../../shared/utils';
import { UserInfo } from '../../../../../functions/src/models/user-info';
import { Section } from '../../../../../functions/src/models/section';
import { CrecheService } from '../../shared/creche.service';
import { UserService } from '../../shared/user.service';
import { AuthService } from '../../shared/auth.service';

export enum UserFormMode {
  CREATE, UPDATE
}

@Component({
  selector: 'app-ppp-admin-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {

  static readonly EMAIL_REGEX = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,4}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/;

  userForm: FormGroup;
  crecheFormControl = new FormControl(null, Validators.required);
  childrenFormControl = new FormControl();
  childrenFilterCtrl: FormControl = new FormControl();
  crechesFilterCtrl: FormControl = new FormControl();
  sectionFormControl = new FormControl();
  roles = enumSelector(Role);
  creches: Array<Creche> = [];
  sections: Array<Section> = [];
  children: Array<UserInfo> = [];
  filteredChildren: ReplaySubject<UserInfo[]> = new ReplaySubject<UserInfo[]>(1);
  filteredCreches: ReplaySubject<Creche[]> = new ReplaySubject<Creche[]>(1);

  loadingCreche = true;
  loadingChildren = false;

  /** Indicates if the user is a child */
  child = false;
  /** Indicates if the user is a parent */
  parent = false;
  /** Indicates if the child has a parent, used only when the role is CHILD */
  hasParent = true;

  roleSelectionAllowed = false;

  crecheToChildren = new Map<string, Array<UserInfo>>();

  @Input() mode: UserFormMode;
  @Input() user: UserInfo;
  @Input() role: Role;

  private isAdmin: boolean;
  private isUpdate: boolean;
  private loadingStatus = new Subject<boolean>();
  private validStatus = new Subject<boolean>();
  private valueStatus = new Subject<any>();
  private loggedInSubscription: Subscription;
  private onDestroy = new Subject<void>();

  constructor(private firestore: AngularFirestore,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private crecheService: CrecheService,
              authService: AuthService) {
    authService.isLoggedIn.subscribe(authStatus => this.isAdmin = authStatus.role === 'admin');
  }

  ngOnInit() {
    this.isUpdate = this.mode === UserFormMode.UPDATE;
    this.child = this.role === Role.CHILD;
    this.parent = this.role === Role.PARENT;

    const controlsConfig = this.createConfig();
    this.userForm = this.formBuilder.group(controlsConfig);

    this.loadCreches();
    this.registerCrecheChange();
    this.checkIfParentIsSet();

    this.userForm.valueChanges.subscribe(val => {
      this.validStatus.next(this.userForm.valid);
      this.valueStatus.next(deepCopy(val));
    });

    this.childrenFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterChildren());

    this.crechesFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterCreches());

    if (this.isUpdate) {
      this.userForm.markAllAsTouched();

      // In update mode, the role can only be changed between manager and staff,
      // there is no need to register to changes in order to update other fields
      if (this.isAdmin && (this.user?.role === 'manager' || this.user?.role === 'staff')) {
        this.roleSelectionAllowed = true;
        this.userForm.get('role').setValue(this.role);
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    if (this.loggedInSubscription !== undefined) {
      this.loggedInSubscription.unsubscribe();
    }
  }

  @Output()
  get loading(): Observable<any> {
    return this.loadingStatus.asObservable();
  }

  @Output()
  get valid(): Observable<boolean> {
    return this.validStatus.asObservable();
  }

  @Output()
  get value(): Observable<any> {
    return this.valueStatus.asObservable();
  }

  private createConfig() {
    let config = {
      firstName: [this.user?.firstName, Validators.required],
      lastName: [this.user?.lastName, Validators.required],
      role: [this.role],
      creches: this.crecheFormControl
    };

    if (this.child) {
      config = Object.assign(config, {
        section: this.sectionFormControl,
        taggable: [this.user?.taggable]
      });
    } else {
      config = Object.assign(config, {
        email: [this.user?.email, [Validators.required, Validators.pattern(UserFormComponent.EMAIL_REGEX)]]
      });
    }

    if (this.parent) {
      config = Object.assign(config, {
        children: this.childrenFormControl
      });
    }

    return config;
  }

  private loadCreches() {
    this.loadingStatus.next(true);
    this.crecheService.getCreches(false)
      .subscribe((creches) => {
        this.creches = creches;
        this.filteredCreches.next(this.creches.slice());
        // If the user has some creches selected, fill the dropdown accordingly
        if (this.user?.creches?.length > 0) {
          // If it's a child, it has only one creche
          if (this.child) {
            this.crecheFormControl.setValue(this.creches.find(c => c.id === this.user.creches[0]));
            this.loadingStatus.next(false);
          } else {
            const selectedCreches = new Array<Creche>();
            this.creches
              .filter(creche => this.user.creches.indexOf(creche.id) !== -1)
              .forEach(creche => selectedCreches.push(creche));
            this.crecheFormControl.setValue(selectedCreches);
            this.initChildrenForm(selectedCreches);
          }
        } else if (creches.length === 1) {
          if (this.child) {
            // Single select dropdown -> value is the value
            this.crecheFormControl.setValue(creches[0]);
            this.loadingStatus.next(false);
          } else {
            // Multi select dropdown -> value is an array
            const values = [creches[0]];
            this.crecheFormControl.setValue(values);
            this.initChildrenForm(values);
          }
        } else {
          this.loadingStatus.next(false);
        }

        // Creches are fully loaded
        this.loadingCreche = false;
      });
  }

  private registerCrecheChange() {
    this.userForm.get('creches').valueChanges.subscribe((creche: any) => {
      if (creche !== null) {
        this.fillSectionsIfChild(creche);
        this.fillChildrenIfParent(creche).subscribe(children => {
          this.children = children.sort((left, right) => {
            return left.firstName.localeCompare(right.firstName) || left.lastName.localeCompare(right.lastName);
          });
          this.filteredChildren.next(this.children.slice());
        });
      }
    });
  }

  private fillSectionsIfChild(creche: Creche) {
    if (this.child) {
      this.sections = creche.sections;
      let childLinkedToSection = null;
      if (this.sections?.length > 0) {
        creche.sections.forEach(section => {
          const child = section.users.find(userId => userId === this.user?.id);
          if (child !== undefined) {
            childLinkedToSection = section;
          }
        });
      }
      this.sectionFormControl.setValue(childLinkedToSection !== null ? childLinkedToSection.name : null);
    } else {
      this.sectionFormControl.setValue( null);
    }
  }

  private initChildrenForm(selectedCreches: Array<Creche>) {
    this.fillChildrenIfParent(selectedCreches).subscribe(children => {
      if (this.user?.children?.length > 0) {
        const childControl = this.userForm.get('children');
        const selectedChildren = new Array<string>();
        this.user.children.forEach(childId => {
          const child = children.find(c => c.id === childId);
          if (child !== undefined) {
            selectedChildren.push(childId);
          }
        });
        childControl.setValue(selectedChildren);
      }
      this.loadingStatus.next(false);
    });
  }

  private fillChildrenIfParent(creche: Array<Creche>): Observable<Array<UserInfo>> {
    if (this.parent) {
      const ids = creche.map(c => c.id);
      this.children = [];
      if (ids.length === 0) {
        return of([]);
      }
      return forkJoin(ids.map(id => this.getChildrenForCreche(id)))
        .pipe(
          concatAll(),
          reduce((acc, arr) => [...acc, ...arr])
        );
    }
    this.loadingStatus.next(false);
    return of([]);
  }

  private getChildrenForCreche(crecheId: string): Observable<Array<UserInfo>> {
    if (this.crecheToChildren.get(crecheId) === undefined) {
      this.loadingChildren = true;
      // First load the parents
      return this.userService.searchUsers(crecheId, 'parent')
        .pipe(
          map(parents => {
            const parentToChildren = new Map<string, Array<string>>();
            parents.forEach(parent => parentToChildren.set(parent.id, parent.children !== null ? parent.children : []));
            return parentToChildren;
          }),
          flatMap(parentToChildren => this.userService.searchUsers(crecheId, 'child')
            .pipe(
              map(children => {
                return children.map(child => {
                  let isSelectable = true;
                  if (this.user !== undefined) {
                    parentToChildren.forEach((values, parentId) => {
                       if (values.indexOf(child.id) !== -1 && parentId !== this.user.id) {
                         isSelectable = false;
                       }
                    });
                  }
                  return Object.assign(child, {selectable: isSelectable});
                });
              }),
              tap(children => {
                this.loadingChildren = false;
                this.crecheToChildren.set(crecheId, children);
              })
            )
          )
        );
    } else {
      return of(this.crecheToChildren.get(crecheId));
    }
  }

  /**
   * Filter the list of <code>filteredChildren</code> based on user's input
   */
  private filterChildren() {
    if (!this.children) {
      return;
    }
    // get the search keyword
    let search = this.childrenFilterCtrl.value;
    if (!search) {
      this.filteredChildren.next(this.children.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the children
    this.filteredChildren.next(
      this.children.filter(child => child.firstName.toLowerCase().indexOf(search) > -1 || child.lastName.toUpperCase().indexOf(search) > -1)
    );
  }

  /**
   * Filter the list of <code>filteredCreches</code> based on user's input
   */
  private filterCreches() {
    if (!this.creches) {
      return;
    }
    // get the search keyword
    let search = this.crechesFilterCtrl.value;
    if (!search) {
      this.filteredCreches.next(this.creches.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the creches
    this.filteredCreches.next(
      this.creches.filter(creche => creche.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private checkIfParentIsSet() {
    if (this.isUpdate && this.child) {
      this.userService.hasParent(this.user.id).subscribe(hasParent => this.hasParent = hasParent);
    }
  }
}
