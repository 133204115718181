import { CustomDatasource } from '../shared/custom.datasource';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { PaginationEndpoint } from '../shared/page';
import { finalize, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Page, PageRequest } from '../../../../functions/src/models/common/pagination';

export class PaginationDatasoure<T> implements CustomDatasource<T> {

  private page = new Subject<PageEvent>();
  private loading = new Subject<boolean>();
  private sort: BehaviorSubject<Sort>;
  private filter: BehaviorSubject<string>;
  public pageStatus: Observable<Page<T>>;
  public loadingStatus: Observable<boolean> = this.loading.asObservable();

  constructor(
    endpoint: PaginationEndpoint<T>,
    initialSort: Sort,
    size = 200) {
    this.sort = new BehaviorSubject<Sort>(initialSort);
    this.filter = new BehaviorSubject<string>(undefined);
    this.pageStatus = combineLatest([this.sort, this.filter]).pipe(
      switchMap(([sort, filter]) => this.page.pipe(
        startWith({pageIndex: 0, pageSize: size}),
        switchMap(p => {
          this.loading.next(true);
          const request = new PageRequest(p.pageIndex, p.pageSize, sort, filter);
          return endpoint(request).pipe(finalize(() => this.loading.next(false)));
        })
      )),
      shareReplay(1)
    );
  }

  set matSort(sort: MatSort) {
    sort.sortChange.subscribe(sortChange => this.sortBy(sortChange));
  }

  sortBy(sort: any) {
    const lastSort = this.sort.getValue();
    const nextSort = {...lastSort, ...sort};
    this.sort.next(nextSort);
  }

  filterBy(value: string) {
    this.filter.next(value);
  }

  fetch(page: PageEvent) {
    this.page.next(page);
  }

  connect(): Observable<T[]> {
    return this.pageStatus.pipe(map(page => page.content));
  }

  disconnect(): void {}
}
