<mat-toolbar color="secondary">
  <mat-toolbar-row>
    <h1>Users</h1>
    <button *appHasAnyRole="['admin']" mat-raised-button color="primary" (click)="goToUserCreation('admin')" class="create-user__button">Create admin</button>
    <button *appHasAnyRole="['admin']" mat-raised-button color="primary" (click)="goToUserCreation('manager')" class="create-user__button">Create manager</button>
    <button *appHasAnyRole="['admin', 'manager']" mat-raised-button color="primary" (click)="goToUserCreation('child')" class="create-user__button">Create child</button>
    <button *appHasAnyRole="['admin']" mat-raised-button color="primary" (click)="goToUserCreation('parent')" class="create-user__button">Create parent</button>
    <button *appHasAnyRole="['admin', 'manager']" mat-raised-button color="primary" (click)="goToUserCreation('staff')" class="create-user__button">Create staff</button>
    <button *appHasAnyRole="['admin', 'manager']" mat-raised-button color="primary" (click)="goToChildrenCreation()" class="create-user__button">Create children</button>
    <button *appHasAnyRole="['admin', 'manager']" mat-raised-button color="primary" (click)="goToChildren()" class="create-user__button">List children</button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="spinner-container" *ngIf="loading">
<mat-spinner class="spinner"></mat-spinner>
</div>

<div class="container" [ngClass]="{'hidden':loading}">
  <div class="filter-container mat-body" *ngIf="creches.length > 1">
    <form [formGroup]="crecheForm">
      <mat-form-field appearance="fill">
        <mat-label>Creche</mat-label>
        <mat-select (selectionChange)="filterByCreche($event.value)" formControlName="creche" #crecheDropdown>
          <mat-option>
            <ngx-mat-select-search [formControl]="crecheFilterCtrl" placeholderLabel="Creche..."></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="">--</mat-option>
          <mat-option *ngFor="let creche of filteredCreches | async" [value]="creche.id">{{creche.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div class="mat-elevation-z4">
    <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [start]="'asc'">First Name</th>
        <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
        <td mat-cell *matCellDef="let element" class="lastname">{{element.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{element.email}}</td>
      </ng-container>

      <ng-container matColumnDef="creches">
        <th mat-header-cell *matHeaderCellDef>Creche(s)</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let name of element.crecheNames">{{name}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let element">{{element.role}}</td>
      </ng-container>

      <ng-container matColumnDef="nanoid">
        <th mat-header-cell *matHeaderCellDef>Child code</th>
        <td mat-cell *matCellDef="let element" (click)="selectCell($event)">
          <span>{{element.nanoid}}</span>
          <button *ngIf="element.nanoid" mat-icon-button (click)="copyToClipboard($event, element.nanoid)"><mat-icon>content_copy</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="termsAcceptationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Terms accepted</th>
        <td mat-cell *matCellDef="let element">{{element.termsAcceptationDate===undefined?'No':'Yes'}}</td>
      </ng-container>

      <ng-container matColumnDef="taggable">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag allowed</th>
        <td mat-cell *matCellDef="let element">{{element.taggable ? 'Yes' : 'No'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;" (click)="editUser(row)"></tr>
    </table>
    <mat-paginator *ngIf="isAdmin && dataSource.pageStatus | async as page"
                   [length]="page.totalElements"
                   [pageSize]="page.size"
                   [pageIndex]="page.index"
                   [hidePageSize]="false"
                   [pageSizeOptions]="[100, 200, 500]"
                   (page)="pageChange($event)">
    </mat-paginator>
  </div>
</div>
