<mat-card id="clients-card">
  <mat-card-title>Clients</mat-card-title>
  <mat-card-content>
    <mat-spinner *ngIf="(loading | async); else clients"></mat-spinner>
  </mat-card-content>
  <ng-template #clients>
    <div><h2>{{clientsCountersModel?.nbrOfParents}}</h2></div>
    <div><h2>{{clientsCountersModel?.nbrOfChildren}}</h2></div>
    <div>Parents</div>
    <div>Child</div>
  </ng-template>
</mat-card>
