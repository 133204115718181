import { createSelector } from '@ngrx/store';
import { CountersState, PublishersState, selectCountersState } from '../counter.state';

export const selectPublishersState = createSelector(
  selectCountersState,
  (state: CountersState) => state.publishersState
);

export const selectPublishersLoading = createSelector(
  selectPublishersState,
  (state: PublishersState) => state?.loading
);

export const selectPublishers = createSelector(
  selectPublishersState,
  (state: PublishersState) => state?.publishers
);
