import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CrecheFormComponent, CrecheFormMode } from '../creche-form/creche-form.component';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Section } from '../../../../../functions/src/models/section';

@Component({
  selector: 'app-ppp-admin-creche-creation',
  templateUrl: './creche-creation.component.html',
  styleUrls: ['./creche-creation.component.scss']
})
export class CrecheCreationComponent implements AfterViewInit {

  @ViewChild(CrecheFormComponent) crecheForm: CrecheFormComponent;
  createMode = CrecheFormMode.CREATE;
  valid: boolean;
  private value: any;

  constructor(private router: Router, private firestore: AngularFirestore) {
  }

  ngAfterViewInit() {
    this.crecheForm.valid.subscribe(valid => this.valid = valid);
    this.crecheForm.value.subscribe(value => this.value = value);
  }

  createCreche() {
    if (!this.valid) {
      return;
    }

    const sections = this.buildSections();

    this.firestore.collection('creches').add({
      name: this.value.name,
      address: this.value.address,
      city: this.value.city,
      zipCode: this.value.zipCode,
      photos: [],
      sections
    }).then(() => {
      console.log('Creche created!');
      this.router.navigate(['creches']);
    }).catch(error => {
      console.log(error);
    });
  }

  private buildSections(): Array<Section> {
    return Object.entries(this.value)
      .filter(e => e[0].startsWith('section_'))
      .reduce((a: Array<string>, e: any) => {
        return [...a, {name: e[1], users: []}];
      }, []) as Array<Section>;
  }
}
