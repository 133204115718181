import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { AuthStatus } from '../auth.status';

@Directive({
  selector: '[appHasAnyRole]'
})
export class HasAnyRoleDirective implements OnDestroy {

  private userInfoSubscription: Subscription;
  private logoutSubscription: Subscription;

  @Input()
  set appHasAnyRole(roles: Array<string>) {
    this.userInfoSubscription = this.authService.isLoggedIn.subscribe((authStatus: AuthStatus) => {
      this.clearView();
      if (roles.includes(authStatus.role)) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    });
    this.logoutSubscription = this.authService.isLoggedIn.subscribe((authStatus: AuthStatus) => {
      if (!authStatus.authenticated) {
        this.clearView();
      }
    });
  }

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>, private authService: AuthService) {
  }

  ngOnDestroy() {
    if (this.userInfoSubscription !== undefined) {
      this.userInfoSubscription.unsubscribe();
    }
    if (this.logoutSubscription !== undefined) {
      this.logoutSubscription.unsubscribe();
    }
  }

  private clearView() {
    this.viewContainerRef.clear();
  }
}
