<mat-toolbar color="secondary">
  <h1>{{creche.name}} - edition</h1>
</mat-toolbar>

<div class="container">
  <mat-card class="creche-card">
    <mat-card-content>
      <app-ppp-admin-creche-form [creche]="creche" [mode]="updateMode"></app-ppp-admin-creche-form>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="updateCreche()" [disabled]="!valid" color="primary" mat-raised-button type="submit">Update</button>
      <button mat-button routerLink="/dashboard">Cancel</button>
    </mat-card-actions>
  </mat-card>
</div>
