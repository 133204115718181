import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-ppp-admin-creche-section',
  templateUrl: './creche-section.component.html',
  styleUrls: ['./creche-section.component.scss']
})
export class CrecheSectionComponent {

  @Input() control: FormControl;
  @Input() controlName: string;
  @Output() onDelete: EventEmitter<string>;

  constructor() {
    this.onDelete = new EventEmitter<any>();
  }

  delete() {
    this.onDelete.emit(this.controlName);
  }
}
