import { createAction, props } from '@ngrx/store';
import { CrechePhotosMetadataModel } from '../../../../../functions/src/models/creches/creche-photos-metadata.model';
import { CrecheModel } from '../../../../../functions/src/models/creches/creche.model';

export enum PhotosMetadataActionsTypes {
  LOAD_PHOTOS_METADATA_COUNTERS = '[PHOTOS_METADATA] Load photos metadata counters',
  PHOTOS_METADATA_COUNTERS_LOADED = '[PHOTOS_METADATA] Photos metadata counters loaded',
  ERROR_LOADING_PHOTO_MEDATA_COUNTERS = '[PHOTOS_METADATA] Error loading photos metadata counters'
}

export const loadPhotosMetadata = createAction(
  PhotosMetadataActionsTypes.LOAD_PHOTOS_METADATA_COUNTERS,
  props<{ creche: CrecheModel }>()
);

export const photosMetadataCountersLoaded = createAction(
  PhotosMetadataActionsTypes.PHOTOS_METADATA_COUNTERS_LOADED,
  props<{ photosLength: number, metamodel: CrechePhotosMetadataModel }>()
);

export const errorLoadingPhotosMetadataCounters = createAction(
  PhotosMetadataActionsTypes.ERROR_LOADING_PHOTO_MEDATA_COUNTERS,
  props<{ error: any }>()
);
