<mat-card class="login-card mat-elevation-z8">
  <mat-card-content>
    <form [formGroup]="loginForm">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput required type="email">
        <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.invalid">
          <span *ngIf="loginForm.controls.email.errors.required">This field is mandatory.</span>
          <span *ngIf="loginForm.controls.email.errors.pattern">This field is invalid.</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput required type="password" (keydown.enter)="login()">
        <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.invalid">
          <span *ngIf="loginForm.controls.email.errors.required">This field is mandatory.</span>
          <span *ngIf="loginForm.controls.email.errors.pattern">This field is invalid.</span>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="login()" type="submit">Login</button>
  </mat-card-actions>
  <div [hidden]="!errorMessage" class="error">
    <span>{{errorMessage}}</span>
  </div>
</mat-card>
