import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UserFormComponent, UserFormMode } from '../user-form/user-form.component';
import { Router } from '@angular/router';
import { UserInfo } from '../../../../../functions/src/models/user-info';
import { UserService } from '../../shared/user.service';
import { tap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-ppp-admin-user-modification',
  templateUrl: './user-modification.component.html',
  styleUrls: ['./user-modification.component.scss']
})
export class UserModificationComponent implements AfterViewInit {

  @ViewChild(UserFormComponent) userForm: UserFormComponent;
  updateMode = UserFormMode.UPDATE;
  valid: boolean;
  user: UserInfo;
  loading: boolean;
  preloading = true;
  private value: any;

  constructor(private router: Router,
              private userService: UserService,
              private location: Location,
              private firebaseFunctions: AngularFireFunctions,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) {
    this.user = router.getCurrentNavigation().extras.state as UserInfo;
  }

  ngAfterViewInit() {
    this.userForm.valid.subscribe(valid => this.valid = valid);
    this.userForm.value.subscribe(value => this.value = value);
    this.userForm.loading.subscribe(preloading => this.preloading = preloading);
  }

  updateUser() {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    this.value.id = this.user.id;
    this.userService.updateUser(this.value)
      .then(() => {
        this.loading = false;
        this.router.navigate(['users']);
      })
      .catch((err) => {
        this.loading = false;
        console.log('Couldn\'t update user:', err);
      });
  }

  goBack() {
    this.location.back();
  }

  deleteUser() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loading = true;
        const deleteUser = this.firebaseFunctions.httpsCallable('deleteUser');
        deleteUser({userId: this.user.id}).pipe(
          tap(() => this.loading = false)
        ).subscribe(
          () => {
            this.snackBar.open('Deletion in progress, this might take a while', null, { duration: 2000 });
            this.router.navigate(['users']);
          },
          error => {
            console.log('Unable to delete user', error);
            this.snackBar.open('Unable to delete user', null, { duration: 2000 });
            this.router.navigate(['users']);
          });
      }
    });
  }
}
