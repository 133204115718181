export class ChildModel {

  public section: string;

  constructor(public id: string,
              public firstName: string,
              public lastName: string,
              public taggable: boolean,
              public nanoid: string,
              public tagCount: number = 0) {
  }

  increaseTagCount() {
    this.tagCount += 1;
  }
}
