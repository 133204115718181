import { createSelector } from '@ngrx/store';
import { CountersState, PhotosCountersState, selectCountersState } from '../counter.state';

export const selectPhotosCountersState = createSelector(
  selectCountersState,
  (state: CountersState) => state.photosCountersState
);

export const selectPhotosCountersLoading = createSelector(
  selectPhotosCountersState,
  (state: PhotosCountersState) => state?.loading
);

export const selectPhotosCounters = createSelector(
  selectPhotosCountersState,
  (state: PhotosCountersState) => state?.photosCounters
);
