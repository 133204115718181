<mat-toolbar color="primary">
  <mat-toolbar-row class="mat-elevation-z6">
    <h1>PPP Admin</h1>
    <div id="menu" *ngIf="loggedIn">
      <button mat-button [routerLink]="'/dashboard'">Dashboard</button>
      <button mat-button [routerLink]="'/users'" *appHasAnyRole="['admin','manager']">Users</button>
      <button mat-button [routerLink]="'/creches'" *appHasAnyRole="['admin','manager']">Creches</button>
      <button mat-button (click)="logout()">Logout</button>
    </div>
    <span class="spacer"></span>
    <span class="email" *ngIf="email">{{email}}&nbsp;-&nbsp;</span>
    <span class="version">{{version}}</span>
  </mat-toolbar-row>
</mat-toolbar>
