import { AfterViewInit, Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CrecheModel } from '../../../../../functions/src/models/creches/creche.model';
import { MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromChildren from '../../state/selector/children.selector';
import * as fromUsers from '../../state/selector/users-counters.selector';
import { MatTableDataSource } from '@angular/material/table';
import { ChildModel } from '../../shared/model/child.model';
import { map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-ppp-admin-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements AfterViewInit {

  authorizedChildrenDatasource: MatTableDataSource<ChildModel>;
  blockedChildrenDatasource: MatTableDataSource<ChildModel>;

  authorizedChildrenColumns: string[] = ['firstName', 'lastName', 'section', 'tagCount'];
  blockedChildrenColumns: string[] = ['firstName', 'lastName', 'section', 'code'];

  loading: Observable<boolean>;

  @ViewChild(MatButtonToggleGroup) sectionsToggles: MatButtonToggleGroup;
  @ViewChildren(MatSort) set matSort(ms: QueryList<MatSort>) {
    this.authorizedSort = ms.toArray()[0];
    this.blockedSort = ms.toArray()[1];
  }

  private authorizedSort: MatSort;
  private blockedSort: MatSort;
  private children: Array<ChildModel>;
  private _creche: CrecheModel;
  private childIdToSection = new Map<string, string>();

  constructor(store: Store,
              private snackBar: MatSnackBar) {
    this.authorizedChildrenDatasource = new MatTableDataSource();
    this.blockedChildrenDatasource = new MatTableDataSource();

    this.loading = store.select(fromUsers.selectUsersCountersLoading);
    store.select(fromChildren.selectChildren)
      .subscribe(children => {
        if (children?.length > 0) {
          children.forEach(child => child.section = this.childIdToSection.get(child.id));
          this.children = children;
          this.filterChildren(this.creche?.sections.map(section => section.name));
        }
      });
  }

  ngAfterViewInit() {
    this.authorizedChildrenDatasource.sort = this.authorizedSort;
    this.blockedChildrenDatasource.sort = this.blockedSort;
  }

  get creche(): CrecheModel {
    return this._creche;
  }

  @Input()
  set creche(creche: CrecheModel) {
    this._creche = creche;
    this._creche?.sections.forEach(section => {
      section.users.forEach(user => this.childIdToSection.set(user, section.name));
    });
    this.resetSectionsToggles();
  }

  sectionChanged(event: MatButtonToggleChange, all = false) {
    const selectedValues = this.sectionsToggles.value;
    console.log('event', event);
    console.log('selected values', selectedValues);

    const totalSections = this.creche.sections.length;
    const isAllChecked = selectedValues.indexOf('all') !== -1;
    const actualValues = selectedValues.filter(value => value !== 'all');

    let filterValues;

    if (all) {
      if (isAllChecked && actualValues.length !== totalSections) {
        filterValues = ['all', ...this.creche.sections.map(section => section.name)];
      } else if (!isAllChecked) {
        filterValues = [];
      }
    } else {
      if (actualValues.length === totalSections) {
        filterValues = ['all', ...actualValues];
      } else {
        filterValues = actualValues;
      }
    }
    this.sectionsToggles.value = filterValues;

    this.filterChildren(filterValues.filter(value => value !== 'all'));
  }

  copyToClipboard(event: any, value: string) {
    event.stopPropagation();
    navigator.clipboard.writeText(value).then(
      () => this.snackBar.open(`${value} copied to clipboard`, null, { duration: 2000 })
    );
  }

  private resetSectionsToggles() {
    if (this.sectionsToggles !== undefined) {
      this.sectionsToggles.selected[0].checked = true;
    }
  }

  private filterChildren(sections: Array<string>) {
    const authorizedChildren = [];
    const blockedChildren = [];
    this.children.forEach(child => {
      if (sections?.indexOf(child.section) !== -1) {
        if (child.taggable) {
          authorizedChildren.push(child);
        } else {
          blockedChildren.push(child);
        }
      }
      this.authorizedChildrenDatasource.data = authorizedChildren;
      this.blockedChildrenDatasource.data = blockedChildren;
    });
  }
}
