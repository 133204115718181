import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ClientsCountersModel } from '../model/clients-counters.model';
import * as fromClientsCounters from '../../state/selector/clients-counters.selector';

@Component({
  selector: 'app-ppp-admin-clients-counter',
  templateUrl: './clients-counter.component.html',
  styleUrls: ['./clients-counter.component.scss']
})
export class ClientsCounterComponent {

  loading: Observable<boolean>;
  clientsCountersModel: ClientsCountersModel;

  constructor(store: Store) {
    this.loading = store.select(fromClientsCounters.selectClientsCountersLoading);
    store.select(fromClientsCounters.selectClientsCounters).subscribe(
      clientsCountersModel => this.clientsCountersModel = clientsCountersModel
    );
  }

}
