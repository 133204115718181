import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CrechePhotosMetadataModel } from '../../../../functions/src/models/creches/creche-photos-metadata.model';
import { CrecheUsersMetadataModel } from '../../../../functions/src/models/creches/creche-users-metadata.model';
import { CrecheModel } from '../../../../functions/src/models/creches/creche.model';

@Injectable({
  providedIn: 'root'
})
export class CrecheService {

  constructor(private firestore: AngularFirestore,
              private fireStorage: AngularFireStorage,
              private firebaseFunctions: AngularFireFunctions) {}

  getCreches(withUsers: boolean = true): Observable<CrecheModel[]> {
    const getCreches = this.firebaseFunctions.httpsCallable<any, CrecheModel[]>('getCreches');
    return getCreches({withUsers});
  }

  getCrecheUsers(crecheId: string): Observable<CrecheUsersMetadataModel> {
    const getCreches = this.firebaseFunctions.httpsCallable<any, CrecheUsersMetadataModel>('getCrecheUsers');
    return getCreches(crecheId);
  }

  /**
   * Loads the number of photos and size related to a creche at once.
   *
   * @param crecheId the creche
   */
  public loadPhotosMetadata(crecheId: string): Observable<CrechePhotosMetadataModel> {
    const getCrecheMetadata = this.firebaseFunctions.httpsCallable<any, CrechePhotosMetadataModel>('getCrecheMetadata');
    return getCrecheMetadata(crecheId);
  }
}
