import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { CrecheFormComponent, CrecheFormMode } from '../creche-form/creche-form.component';
import { Creche } from '../../../../../functions/src/models/creche';
import { Section } from '../../../../../functions/src/models/section';
import { AuthService } from '../../shared/auth.service';
import { AuthStatus } from '../../shared/auth.status';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ppp-admin-creche-modification',
  templateUrl: './creche-modification.component.html',
  styleUrls: ['./creche-modification.component.scss']
})
export class CrecheModificationComponent implements AfterViewInit, OnDestroy {

  @ViewChild(CrecheFormComponent) crecheForm: CrecheFormComponent;
  updateMode = CrecheFormMode.UPDATE;
  valid: boolean;
  creche: Creche;
  private value: any;
  private isAdmin = false;
  private loggedInSubscription: Subscription;

  constructor(private router: Router,
              private firestore: AngularFirestore,
              private authService: AuthService) {
    this.creche = router.getCurrentNavigation().extras.state as Creche;
    this.loggedInSubscription = this.authService.isLoggedIn.subscribe((authStatus: AuthStatus) => this.isAdmin = authStatus.role === 'admin');
  }

  ngOnDestroy() {
    this.loggedInSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.crecheForm.valid.subscribe(valid => this.valid = valid);
    this.crecheForm.value.subscribe(value => this.value = value);
  }

  updateCreche() {
    if (!this.valid) {
      return;
    }

    // Replace the full Creche object by a simple list of ids
    const sections = this.buildSections();

    let updatedCreche;
    if (this.isAdmin) {
      updatedCreche = {
        name: this.value.name,
        address: this.value.address,
        city: this.value.city,
        zipCode: this.value.zipCode,
        sections
      };
    } else {
      updatedCreche = {
        sections
      };
    }

    this.firestore.collection('creches').doc(this.creche.id).update(updatedCreche).then(() => {
      console.log('Creche updated!');
      this.router.navigate(['dashboard']);
    }).catch(error => {
      console.log(error);
    });
  }

  private buildSections(): Array<any> {
    const originalSections = new Map<string, Section>();
    if (!this.creche.sections) {
      this.creche.sections = [];
    }
    this.creche.sections.map((section, index) => originalSections.set('section_' + (index + 1), section));

    return Object.entries(this.value)
      .filter(e => e[0].startsWith('section_'))
      .reduce((a: Array<string>, e: any) => {
        const index = e[0];
        const originalSection = originalSections.get(index);
        const section = e[1];
        if (originalSection !== undefined && originalSection.users !== undefined) {
          return [...a, { name: section, users: originalSection.users }];
        } else {
          return [...a, { name: section, users: [] }];
        }
      }, []) as Array<Section>;
  }
}
