export function enumSelector(definition) {
  return Object.keys(definition).map(key => ({ value: definition[key], title: key }));
}

export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function chunkArray<T>(array: Array<T>, size: number): Array<T> {
  const chunkedArray = [];

  for (let index = 0; index < array.length; index += size) {
    chunkedArray.push(array.slice(index, index + size));
  }

  return chunkedArray;
}
