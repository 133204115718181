<mat-card id="photos">
  <mat-card-title>Photos (last 30 days)</mat-card-title>
  <mat-card-content>
    <mat-spinner *ngIf="(loading | async); else photos"></mat-spinner>
  </mat-card-content>
  <ng-template #photos>
    <div><h2>{{photosCountersModel.nbrOfPhotos}}</h2></div>
    <div><h2>{{photosCountersModel.nbrOfRefs}}</h2></div>
    <div class="larger"><h2>{{photosCountersModel.storageSize | storage: '1.2-2'}}</h2></div>
    <div>Photos</div>
    <div>Refs</div>
    <div class="larger">Storage</div>
  </ng-template>
</mat-card>
