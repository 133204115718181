<mat-toolbar color="secondary">
  <h1>New creche</h1>
</mat-toolbar>

<div class="container">
  <mat-card class="creche-card">
    <mat-card-content>
      <app-ppp-admin-creche-form [mode]="createMode"></app-ppp-admin-creche-form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="createCreche()" [disabled]="!valid" type="submit">Create</button>
      <button mat-button routerDirection="back" routerLink="/creches">Cancel</button>
    </mat-card-actions>
  </mat-card>
</div>
