import { createSelector } from '@ngrx/store';
import { ChildrenState, CountersState, selectCountersState } from '../counter.state';

export const selectChildrenState = createSelector(
  selectCountersState,
  (state: CountersState) => state.childrenState
);

export const selectChildren = createSelector(
  selectChildrenState,
  (state: ChildrenState) => state.children
);
