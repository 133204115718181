import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CrecheService } from '../../shared/creche.service';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PhotosMetadataActions, UsersActions } from '../action';

@Injectable()
export class CrecheEffect {

  getCrecheUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.loadUsersCounters),
      exhaustMap(action =>
        this.crecheService.getCrecheUsers(action.crecheId).pipe(
          map(metamodel => UsersActions.usersCountersLoaded({ metamodel })),
          catchError(error => of(UsersActions.errorLoadingUsersCounters({ error })))
        )
      )
    )
  );

  loadPhotosMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PhotosMetadataActions.loadPhotosMetadata),
      exhaustMap(action =>
        this.crecheService.loadPhotosMetadata(action.creche.id).pipe(
          map(metamodel => PhotosMetadataActions.photosMetadataCountersLoaded({ photosLength: action.creche.photos.length, metamodel })),
          catchError(error => of(PhotosMetadataActions.errorLoadingPhotosMetadataCounters({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions,
              private crecheService: CrecheService) {
  }
}
