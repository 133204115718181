<div class="spinner-container" *ngIf="loading">
  <mat-spinner class="spinner"></mat-spinner>
</div>

<div class="container" [ngClass]="{'hidden':loading}">

  <form [formGroup]="childrenForm">
    <div class="filter-container mat-body">
      <mat-form-field appearance="fill" disabled="!loading">
        <mat-label>Creche</mat-label>
        <mat-select (selectionChange)="getChildrenAndUpdateSections($event.value)" formControlName="creche">
          <mat-option>
            <ngx-mat-select-search [formControl]="crecheFilterCtrl" placeholderLabel="Creche..."></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="">--</mat-option>
          <mat-option *ngFor="let creche of filteredCreches | async" [value]="creche.id">{{creche.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="sections?.length > 0">
        <mat-label>Section</mat-label>
        <mat-select (selectionChange)="getChildren($event.value)" formControlName="section">
          <mat-option>
            <ngx-mat-select-search [formControl]="sectionFilterCtrl" placeholderLabel="Section..."></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="">--</mat-option>
          <mat-option *ngFor="let section of filteredSections | async" [value]="section.name">{{section.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <table mat-table matTableExporter [dataSource]="dataSource" [ngClass]="{'hidden':children.length === 0}" #exporter="matTableExporter">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let element" class="lastname">{{element.lastName}}</td>
    </ng-container>

    <ng-container matColumnDef="section">
      <th mat-header-cell *matHeaderCellDef>Section</th>
      <td mat-cell *matCellDef="let element">{{element.section}}</td>
    </ng-container>

    <ng-container matColumnDef="nanoid">
      <th mat-header-cell *matHeaderCellDef>Child Code</th>
      <td mat-cell *matCellDef="let element">{{element.nanoid}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;">
  </table>

  <button mat-stroked-button *ngIf="children.length > 0" (click)="exporter.exportTable('xlsx', {fileName:'creche_export', sheet: 'children'})">Export in XLSX</button>
  <button mat-stroked-button routerDirection="back" routerLink="/users" class="back-button">Go back</button>

</div>
