<mat-card id="parents" *ngIf="(loading | async) || parentsDatasource.data.length > 0">
  <mat-card-title>
    Parents never connected <span class="title-level-2" *ngIf="!(loadingClients | async)">{{parentsDatasource.data.length}}/{{nbrOfParents}}</span>
  </mat-card-title>
  <mat-card-content>
    <mat-spinner *ngIf="(loading | async)"></mat-spinner>
    <table mat-table [ngClass]="{'hidden': parentsDatasource.data.length === 0}" [dataSource]="parentsDatasource" matSort>
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Firstname</th>
        <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lastname</th>
        <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{element.email}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="parentsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: parentsColumns;">
    </table>
  </mat-card-content>
</mat-card>
