<div class="spinner-container" *ngIf="loading">
  <mat-spinner class="spinner"></mat-spinner>
</div>

<div class="container" [ngClass]="{'hidden':loading}">
  <div class="filter-container mat-body" *ngIf="creches.length > 1; else singleCreche">
    <form [formGroup]="crecheForm">
      <mat-form-field appearance="fill">
        <mat-label>Creche</mat-label>
        <mat-select (selectionChange)="filterByCreche($event.value)" formControlName="creche" #crecheDropdown>
          <mat-option>
            <ngx-mat-select-search [formControl]="crecheFilterCtrl" placeholderLabel="Creche..."></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="">--</mat-option>
          <mat-option *ngFor="let creche of filteredCreches | async" [value]="creche.id">{{creche.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <ng-template #singleCreche>
    <h2>{{creches[0]?.name}}</h2>
  </ng-template>

  <hr class="separator" />

  <div class="container__actions" *appHasAnyRole="['admin','manager']">
    <button *appHasAnyRole="['admin','manager']" mat-raised-button color="primary" [routerLink]="['../creches',crecheId,'update']" [state]="creche">Edit</button>
    <button *appHasAnyRole="['admin']" (click)="deleteCreche()" color="warn" mat-raised-button type="submit">Delete</button>
  </div>

  <!-- COUNTERS -->
  <div id="counters">
    <app-ppp-admin-users-counter></app-ppp-admin-users-counter>
    <app-ppp-admin-clients-counter></app-ppp-admin-clients-counter>
    <app-ppp-admin-photos-counter></app-ppp-admin-photos-counter>
    <app-ppp-admin-publications-counter></app-ppp-admin-publications-counter>
  </div>

  <!-- SECTIONS -->
  <h2>Sections</h2>
  <div id="sections">
    <app-ppp-admin-sections [creche]="creche"></app-ppp-admin-sections>
  </div>

  <!-- PUBLISHERS, PARENTS & STAFF -->
  <h2>Users</h2>
  <div id="users">
    <app-ppp-admin-publishers></app-ppp-admin-publishers>
    <app-admin-parents-never-connected></app-admin-parents-never-connected>
    <app-ppp-admin-staff-never-connected></app-ppp-admin-staff-never-connected>
  </div>
</div>
