import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfo } from '../../../../../functions/src/models/user-info';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import * as fromStaff from '../../state/selector/staff.selector';
import * as fromUsers from '../../state/selector/users-counters.selector';

@Component({
  selector: 'app-ppp-admin-staff-never-connected',
  templateUrl: './staff-never-connected.component.html',
  styleUrls: ['./staff-never-connected.component.scss']
})
export class StaffNeverConnectedComponent implements AfterViewInit {

  loading: Observable<boolean>;
  loadingUsers: Observable<boolean>;
  staffDatasource: MatTableDataSource<UserInfo>;
  staffColumns: string[] = [];
  nbrOfStaff: number;

  @ViewChild(MatSort) sort: MatSort;

  constructor(store: Store) {
    this.staffDatasource = new MatTableDataSource();
    this.setDisplayedColumns();

    this.loading = store.select(fromStaff.selectStaffLoading);
    this.loadingUsers = store.select(fromUsers.selectUsersCountersLoading);

    store.select(fromStaff.selectStaff).subscribe(
      staff => this.staffDatasource.data = staff
    );
    store.select(fromUsers.selectUsersCounters).subscribe(
      counter => this.nbrOfStaff = counter?.nbrOfStaff
    );
  }

  ngAfterViewInit() {
    this.staffDatasource.sort = this.sort;
  }

  @HostListener('window:resize')
  onResize() {
    this.setDisplayedColumns();
  }

  private setDisplayedColumns() {
    if (window.innerWidth < 660) {
      this.staffColumns = ['firstName', 'lastName'];
    } else {
      this.staffColumns = ['firstName', 'lastName', 'email'];
    }
  }

}
