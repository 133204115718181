import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { NoOpAction, PhotosActions, TaggedChildrenActions, UsersActions } from '../action';
import { switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { PhotoModel } from '../../shared/model/photo.model';
import { ChildModel } from '../../shared/model/child.model';
import { UserInfo } from '../../../../../functions/src/models/user-info';
import { CountersSelector } from '../counter.state';

@Injectable({ providedIn: 'root' })
export class TaggedChildrenEffect {

  taggedChildrenEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.usersCountersLoaded, PhotosActions.photosLoaded),
      concatLatestFrom(() => [
          this.countersSelector.getMetadata(),
          this.countersSelector.getPhotos()
        ]),
      switchMap(([_, metadata, photos]) => {
        let children;
        if (metadata !== undefined && photos !== undefined) {
          children = this.distinctTaggedUser(metadata.children, photos);
        }
        return of(TaggedChildrenActions.childrenTagged({ children }));
      })
    )
  );

  constructor(private actions$: Actions,
              private countersSelector: CountersSelector) {
  }

  private distinctTaggedUser(children: Array<UserInfo>, photos: Array<PhotoModel>): Array<ChildModel> {
    const models = new Array<ChildModel>();

    children.forEach(child => {
      const model = new ChildModel(child.id, child.firstName, child.lastName, child.taggable, child.nanoid);
      models.push(model);
      photos.forEach(photo => {
        const tag = photo.tags.find(t => t === child.id);
        if (tag !== undefined) {
          model.increaseTagCount();
        }
      });
    });

    return models.sort((a, b) => b.tagCount - a.tagCount);
  }
}
