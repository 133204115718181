import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'storage'
})
export class StoragePipe implements PipeTransform {

  transform(value: number, digitsInfo: string): string {
    return this.formatSize(value, digitsInfo);
  }

  private formatSize(size: number, digitsInfo: string): string {
    let convertedSize = size / 1000000;
    let unit = "Mo(s)";
    if (Math.round(convertedSize) > 1000) {
      convertedSize = convertedSize / 1000;
      unit = "Go(s)";
    }
    return formatNumber(convertedSize, 'en-US', digitsInfo) + unit;
  }
}
