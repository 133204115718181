<form [formGroup]="userForm">
  <!-- ROLE (ONLY FOR ADMIN) -->
  <mat-form-field appearance="fill" *ngIf="roleSelectionAllowed">
    <mat-label>Role</mat-label>
    <mat-select formControlName="role">
      <mat-option value="manager">Manager</mat-option>
      <mat-option value="staff">Staff</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- FIRST NAME -->
  <mat-form-field appearance="outline" class="form-field">
    <mat-label>First name</mat-label>
    <input formControlName="firstName" matInput required>
    <mat-error *ngIf="userForm.controls.firstName.touched && userForm.controls.firstName.invalid">
      <span *ngIf="userForm.controls.firstName.errors.required">This field is mandatory.</span>
      <span *ngIf="userForm.controls.firstName.errors.pattern">This field is invalid.</span>
    </mat-error>
  </mat-form-field>

  <!-- LAST NAME -->
  <mat-form-field appearance="outline" class="form-field">
    <mat-label>Last name</mat-label>
    <input formControlName="lastName" matInput required>
    <mat-error *ngIf="userForm.controls.lastName.touched && userForm.controls.lastName.invalid">
      <span *ngIf="userForm.controls.lastName.errors.required">This field is mandatory.</span>
      <span *ngIf="userForm.controls.lastName.errors.pattern">This field is invalid.</span>
    </mat-error>
  </mat-form-field>

  <!-- EMAIL -->
  <mat-form-field appearance="outline" class="form-field" *ngIf="userForm.controls.email">
    <mat-label>Email</mat-label>
    <input formControlName="email" matInput required>
    <mat-error *ngIf="userForm.controls.email.touched && userForm.controls.email.invalid">
      <span *ngIf="userForm.controls.email.errors.required">This field is mandatory.</span>
      <span *ngIf="userForm.controls.email.errors.pattern">This field is invalid.</span>
    </mat-error>
  </mat-form-field>

  <!-- CRECHE -->
  <app-ppp-admin-loading-field [loading]="loadingCreche" [message]="'Loading creches'">
    <mat-form-field appearance="fill" field>
      <mat-label>Creche</mat-label>
      <!-- Current user is not a child: he can belong to one or more creches -->
      <mat-select [formControl]="crecheFormControl" *ngIf="!child" multiple>
        <mat-select-trigger>
          {{crecheFormControl.value?.length > 0 ? crecheFormControl.value[0].name : ''}}
          <span *ngIf="crecheFormControl.value?.length > 1">
            (+{{crecheFormControl.value.length - 1}} {{crecheFormControl.value?.length === 2 ? 'other' : 'others'}})
          </span>
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search [formControl]="crechesFilterCtrl" placeholderLabel="Creche..."></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let creche of filteredCreches | async" [value]="creche">{{creche.name}}</mat-option>
      </mat-select>

      <!-- Current user is a child: it can belong to only one creche -->
      <mat-select [formControl]="crecheFormControl" *ngIf="child">
        <mat-select-trigger>
          {{crecheFormControl.value ? crecheFormControl.value.name : ''}}
        </mat-select-trigger>
        <mat-option *ngFor="let creche of creches" [value]="creche">{{creche.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </app-ppp-admin-loading-field>

  <!-- CHILDREN -->
  <app-ppp-admin-loading-field [loading]="loadingChildren" [message]="'Loading children'">
    <mat-form-field *ngIf="parent && children.length > 0" field appearance="fill">
      <mat-label>Children</mat-label>
      <mat-select [formControl]="childrenFormControl" multiple>
        <mat-option>
          <ngx-mat-select-search [formControl]="childrenFilterCtrl" placeholderLabel="Child..."></ngx-mat-select-search>
        </mat-option>
        <ng-container *ngFor="let child of filteredChildren | async">
          <mat-option *ngIf="child.selectable; else disabled" [value]="child.id">{{child.firstName}} - {{child.lastName}}</mat-option>
          <ng-template #disabled>
            <mat-option [value]="child.id" disabled>{{child.firstName}} - {{child.lastName}}</mat-option>
          </ng-template>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </app-ppp-admin-loading-field>

  <!-- SECTION -->
  <mat-form-field *ngIf="userForm.controls.section && !loadingCreche" appearance="fill">
    <mat-label>Section</mat-label>
    <mat-select [formControl]="sectionFormControl">
      <mat-option>--</mat-option>
      <mat-option *ngFor="let section of sections" [value]="section.name">{{section.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox *ngIf="userForm.controls.taggable" formControlName="taggable">Parental' Authorization</mat-checkbox>

</form>
