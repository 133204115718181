import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { CrecheModel } from '../../../../../functions/src/models/creches/creche.model';
import { Section } from '../../../../../functions/src/models/section';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { CrecheService } from '../../shared/creche.service';
import { UserService } from '../../shared/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { Child } from '../../shared/model/children.model';

@Component({
  selector: 'app-ppp-admin-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit, OnDestroy {

  loading = true;

  children: Array<Child> = [];
  dataSource = new MatTableDataSource<Child>(this.children);
  columns: string[] = ['firstName', 'lastName', 'section', 'nanoid'];
  childrenForm: FormGroup;

  filteredCreches: ReplaySubject<CrecheModel[]> = new ReplaySubject<CrecheModel[]>(1);
  crecheFilterCtrl: FormControl = new FormControl();
  creches: Array<CrecheModel> = [];

  filteredSections: ReplaySubject<Section[]> = new ReplaySubject<Section[]>(1);
  sectionFilterCtrl: FormControl = new FormControl();
  sections: Array<Section> = [];

  private crechesSubscription: Subscription;
  private onDestroy = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private crecheService: CrecheService,
              private userService: UserService) { }

  ngOnInit() {
    this.childrenForm = this.formBuilder.group({
      creche: ['', Validators.required],
      section: ['']
    });

    this.loadCreches();

    this.crecheFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterCreches());

    this.sectionFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterSections());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getChildrenAndUpdateSections(crecheId) {
    this.getChildren();
    this.getSections(crecheId);
  }

  getChildren(sectionName: string = null) {
    this.loading = true;
    this.userService.getChildren(this.childrenForm.get('creche').value, sectionName).subscribe(response => {
      this.children = response.children;
      this.dataSource.data = this.children;
      this.loading = false;
    });
  }

  private getSections(crecheId: string) {
    this.sections = this.creches.filter(creche => creche.id === crecheId).shift().sections;
    this.filteredSections.next(this.sections.slice());
  }

  private loadCreches() {
    this.crechesSubscription = this.crecheService.getCreches(false)
      .pipe(tap(() => this.loading = false))
      .subscribe(creches => {
        this.creches = creches;
        this.filteredCreches.next(creches.slice());
      });
  }

  /**
   * Filter the list of <code>filteredCreches</code> based on user's input
   */
  private filterCreches() {
    if (!this.creches) {
      return;
    }
    // get the search keyword
    let search = this.crecheFilterCtrl.value;
    if (!search) {
      this.filteredCreches.next(this.creches.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the creches
    this.filteredCreches.next(
      this.creches.filter(creche => creche.name.toLowerCase().indexOf(search) > -1)
    );
  }

  /**
   * Filter the list of <code>filteredSection</code> based on user's input
   */
  private filterSections() {
    if (!this.sections) {
      return;
    }
    // get the search keyword
    let search = this.sectionFilterCtrl.value;
    if (!search) {
      this.filteredSections.next(this.sections.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the creches
    this.filteredSections.next(
      this.sections.filter(section => section.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
