import { NgModule } from '@angular/core';
import { hasCustomClaim, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { pipe } from 'rxjs';
import { AdminGuard } from './admin.guard';
import { CrecheCreationComponent } from './creches/creche-creation/creche-creation.component';
import { CrechesComponent } from './creches/creches.component';
import { LoginComponent } from './login/login.component';
import { UserCreationComponent } from './users/user-creation/user-creation.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { UsersComponent } from './users/users.component';
import { UserModificationComponent } from './users/user-modification/user-modification.component';
import { CrecheModificationComponent } from './creches/creche-modification/creche-modification.component';
import { ChildrenCreationComponent } from './users/children-creation/children-creation.component';
import { ChildrenComponent } from './users/children/children.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => pipe(redirectUnauthorizedTo(['login']), adminOnly);
const redirectLoggedInToHome = () => redirectLoggedInTo(['users']);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: 'users/create',
    component: UserCreationComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: 'users/create-children',
    component: ChildrenCreationComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: 'users/children',
    component: ChildrenComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: 'users/:id',
    component: UserDetailsComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: 'users/:id/update',
    component: UserModificationComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: 'creches',
    component: CrechesComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: 'creches/create',
    component: CrecheCreationComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: 'creches/:id/update',
    component: CrecheModificationComponent,
    canActivate: [AdminGuard],
    data: {
      roles: ['admin', 'manager']
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
