<mat-toolbar color="secondary">
  <h1>New {{role || 'user'}}</h1>
</mat-toolbar>

<div class="small-spinner-container" *ngIf="preloading">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>

<div class="container" *ngIf="!loading" [ngClass]="{'hidden': preloading}">
  <mat-card class="user-card">
    <mat-card-content>
      <app-ppp-admin-user-form [mode]="createMode" [role]="role"></app-ppp-admin-user-form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="createUser()" [disabled]="!valid" type="submit">Create</button>
      <button mat-button routerDirection="back" routerLink="/users">Cancel</button>
    </mat-card-actions>
  </mat-card>
</div>

<div class="spinner-container" *ngIf="loading">
  <mat-spinner class="spinner"></mat-spinner>
</div>
