import { Component } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { AuthStatus } from '../shared/auth.status';
import { appVersion } from '../app.version';

@Component({
  selector: 'app-ppp-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  version: string = appVersion;
  loggedIn = false;
  email: string;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.isLoggedIn.subscribe((status: AuthStatus) => {
      this.email = status.email;
      this.loggedIn = status.authorized;
    });
  }

  logout() {
    this.authService.logout().then(() => {
      this.email = undefined;
      this.router.navigate(['login']);
    });
  }
}
