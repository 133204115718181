<form [formGroup]="crecheForm">
  <mat-form-field appearance="outline" class="form-field">
    <mat-label>Name</mat-label>
    <input formControlName="name" required matInput>
    <mat-error *ngIf="crecheForm.controls.name.touched && crecheForm.controls.name.invalid">
      <span *ngIf="crecheForm.controls.name.errors.required">This field is mandatory.</span>
      <span *ngIf="crecheForm.controls.name.errors.pattern">This field is invalid.</span>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="form-field">
    <mat-label>Address</mat-label>
    <input formControlName="address" required matInput>
    <mat-error *ngIf="crecheForm.controls.address.touched && crecheForm.controls.address.invalid">
      <span *ngIf="crecheForm.controls.address.errors.required">This field is mandatory.</span>
      <span *ngIf="crecheForm.controls.address.errors.pattern">This field is invalid.</span>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="form-field">
    <mat-label>City</mat-label>
    <input formControlName="city" required matInput>
    <mat-error *ngIf="crecheForm.controls.city.touched && crecheForm.controls.city.invalid">
      <span *ngIf="crecheForm.controls.city.errors.required">This field is mandatory.</span>
      <span *ngIf="crecheForm.controls.city.errors.pattern">This field is invalid.</span>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="form-field">
    <mat-label>Zip code</mat-label>
    <input formControlName="zipCode" required matInput>
    <mat-error *ngIf="crecheForm.controls.zipCode.touched && crecheForm.controls.zipCode.invalid">
      <span *ngIf="crecheForm.controls.zipCode.errors.required">This field is mandatory.</span>
      <span *ngIf="crecheForm.controls.zipCode.errors.pattern">This field is invalid.</span>
    </mat-error>
  </mat-form-field>
  <div class="section__container">
    <div>
      <mat-form-field>
        <mat-label>Sections</mat-label>
        <input matInput [formControl]="sectionFormControl">
      </mat-form-field>
    </div>
    <button mat-stroked-button color="primary" (click)="addSection()">Add</button>
  </div>
  <app-ppp-admin-creche-section *ngFor="let entry of sections | keyvalue" [controlName]="entry.key" [control]="entry.value" (onDelete)="removeSection($event)"></app-ppp-admin-creche-section>
</form>
