import { UsersCountersModel } from '../shared/model/users-counters.model';
import { ClientsCountersModel } from '../shared/model/clients-counters.model';
import { UserInfo } from '../../../../functions/src/models/user-info';
import { PhotosCountersModel } from '../shared/model/photos-counters.model';
import { PublisherModel } from '../shared/model/publisher.model';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { CrecheUsersMetadataModel } from '../../../../functions/src/models/creches/creche-users-metadata.model';
import { PhotoModel } from '../shared/model/photo.model';
import { ChildModel } from '../shared/model/child.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

export interface UsersCountersState {
  loading: boolean;
  usersCounters: UsersCountersModel;
}

export interface ClientsCountersState {
  loading: boolean;
  clientsCounters: ClientsCountersModel;
}

export interface ParentsNeverConnectedState {
  loading: boolean;
  parents: Array<UserInfo>;
}

export interface StaffNeverConnectedState {
  loading: boolean;
  staff: Array<UserInfo>;
}

export interface ChildrenState {
  loading: boolean;
  children: Array<ChildModel>;
}

export interface PhotosCountersState {
  loading: boolean;
  photosCounters: PhotosCountersModel;
}

export interface PublishersState {
  loading: boolean;
  publishers: Array<PublisherModel>;
}

export interface PublicationsState {
  nbrOfPublished: number;
}

export interface CountersState {
  metadata: CrecheUsersMetadataModel;
  photos: Array<PhotoModel>;
  usersCountersState: UsersCountersState;
  parentsState: ParentsNeverConnectedState;
  staffState: StaffNeverConnectedState;
  clientsCountersState: ClientsCountersState;
  childrenState: ChildrenState;
  photosCountersState: PhotosCountersState;
  publishersState: PublishersState;
  publicationsState: PublicationsState;
}

export const selectCountersState = createFeatureSelector<CountersState>('counters');

export const selectCountersMetadata = createSelector(
  selectCountersState,
  (state: CountersState) => state.metadata
);

export const selectCountersPhotos = createSelector(
  selectCountersState,
  (state: CountersState) => state.photos
);

@Injectable()
export class CountersSelector {
  constructor(private readonly store: Store<CountersState>) {
  }

  getMetadata(): Observable<CrecheUsersMetadataModel> {
    return this.store.select(selectCountersMetadata);
  }

  getPhotos(): Observable<Array<PhotoModel>> {
    return this.store.select(selectCountersPhotos);
  }
}


