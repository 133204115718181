import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CrecheService } from '../../shared/creche.service';
import { CrecheModel } from '../../../../../functions/src/models/creches/creche.model';

@Component({
  selector: 'app-ppp-admin-creche-column-metadata',
  templateUrl: './creche-column-metadata.component.html',
  styleUrls: ['./creche-column-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrecheColumnMetadataComponent {

  loading = false;
  loaded = false;
  nbrOfPhotos: number;
  storageSize: number;
  @Input() creche: CrecheModel;

  constructor(private crecheService: CrecheService,
              private changeDetectorRef: ChangeDetectorRef) { }

  loadPhotos() {
    this.loading = true;
    this.crecheService.loadPhotosMetadata(this.creche.id)
      .subscribe(metadata => {
        this.loaded = true;
        this.loading = false;
        this.nbrOfPhotos = metadata.nbrOfPhotos;
        this.storageSize = metadata.storageSize;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
  }
}
