import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../shared/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthStatus} from '../shared/auth.status';

@Component({
  selector: 'app-ppp-admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  errorMessage: string;
  loginForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  login() {
    this.authService.login(this.loginForm.value.email, this.loginForm.value.password).then((status: AuthStatus) => {
      if (status.authorized) {
        this.errorMessage = '';
        this.router.navigate(['']);
      } else {
        this.errorMessage = status.error;
      }
    });
  }
}
