import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, ReplaySubject } from 'rxjs';
import { User } from 'firebase';
import { filter, map, take } from 'rxjs/operators';
import { AuthStatus } from './auth.status';
import { UserInfo } from '../../../../functions/src/models/user-info';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<User>;
  userInfo: Observable<UserInfo>;
  loggedIn: Observable<AuthStatus>;
  private loggedInState = new ReplaySubject<AuthStatus>(1);
  private userInfoState = new ReplaySubject<UserInfo>(1);

  constructor(private auth: AngularFireAuth,
              private firestore: AngularFirestore) {
    this.user = auth.authState;
    this.userInfo = this.userInfoState.asObservable();
    this.loggedIn = this.loggedInState.asObservable();
    this.user
      .pipe(filter(user => user !== null))
      .subscribe(async () => {
        const authStatus = await this.getAuthStatus();
        this.loggedInState.next(authStatus);

        this.firestore.collection('users').doc(authStatus.userId).get()
            .pipe(map(snapshot => snapshot.data() as UserInfo))
            .subscribe(userInfo => this.userInfoState.next(userInfo));
      });
  }

  get isLoggedIn(): Observable<AuthStatus> {
    return this.loggedIn;
  }

  login(username: string, password: string): Promise<AuthStatus> {
    return this.auth.signInWithEmailAndPassword(username, password)
      .then(async (user) => {
        console.debug('User logged in', user);
        const authStatus = await this.getAuthStatus();
        this.loggedInState.next(authStatus);
        return Promise.resolve(authStatus);
      })
      .catch(err => {
        console.debug('Couldn\'t log in', err);
        const authStatus = new AuthStatus(null, null, null, err);
        this.loggedInState.next(authStatus);
        return Promise.resolve(authStatus);
      });
  }

  logout(): Promise<void> {
    return this.auth.signOut().then(() => this.loggedInState.next(new AuthStatus(null, null, null, null)));
  }

  private async getAuthStatus(): Promise<AuthStatus> {
    const token = await this.auth.idTokenResult.pipe(take(1)).toPromise();
    return AuthStatus.of(token);
  }
}
