import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CreateUserRequest } from '../../../../functions/src/models/requests/create-user-request';
import { UserInfo } from '../../../../functions/src/models/user-info';
import { Role } from '../../../../functions/src/models/role.enum';
import { from, Observable, of } from 'rxjs';
import { concatMap, flatMap, map, take, tap } from 'rxjs/operators';
import { Creche } from '../../../../functions/src/models/creche';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Section } from '../../../../functions/src/models/section';
import { Page, PageRequest } from '../../../../functions/src/models/common/pagination';
import { CreateChildrenRequest } from '../../../../functions/src/models/requests/create-children.request';
import { ChildrenModel } from './model/children.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentPage = -1;
  private startAt = new Array<string>();
  private startAfter: string;

  constructor(private firestore: AngularFirestore,
              private firebaseFunctions: AngularFireFunctions) { }

  getUserDetails(uid: string): Observable<any> {
    const getUserDetails = this.firebaseFunctions.httpsCallable<any, Page<UserInfo>>('getUserDetails');
    return getUserDetails({uid});
  }

  getUsers(request: PageRequest): Observable<Page<UserInfo>> {
    if (request.page === 0) {
      request.startAt = null;
      request.startAfter = null;
      this.currentPage = -1;
      this.startAt = [];
      this.startAfter = undefined;
    }

    const getUsers = this.firebaseFunctions.httpsCallable<any, Page<UserInfo>>('getUsers');

    // Navigate to previous
    if (this.startAt !== undefined && request.page < this.currentPage) {
      this.startAt.pop();
      request.startAt = this.startAt.pop();
    } else if (this.startAfter !== undefined) { // Navigate to next
      request.startAfter = this.startAfter;
    }

    return getUsers(request).pipe(
      tap(page => {
        this.startAt.push(page.startAt);
        this.startAfter = page.startAfter;
        this.currentPage = request.page;
      }),
    );
  }

  searchUsers(crecheId: string, role: string): Observable<UserInfo[]> {
    const searchUsers = this.firebaseFunctions.httpsCallable<any, UserInfo[]>('searchUsers');
    return searchUsers({crecheId, role});
  }

  createUser(value: any): Observable<any> {
    return this.initDataAndExecute(value, (user, crecheId, sectionName) => {
      const request = new CreateUserRequest(value, this.shouldCreateAccount(user));
      const createUser = this.firebaseFunctions.httpsCallable<CreateUserRequest, any>('createUser');
      return createUser(request).pipe(flatMap(createdUser =>
        this.addChildToSection(createdUser, crecheId, sectionName)
      ));
    });
  }

  createChildren(request: CreateChildrenRequest): Observable<any> {
    const createChildren = this.firebaseFunctions.httpsCallable<CreateChildrenRequest, any>('createChildren');
    return createChildren(request);
  }

  getChildren(crecheId: string, sectionName: string = null): Observable<ChildrenModel> {
    const getChildren = this.firebaseFunctions.httpsCallable<any, ChildrenModel>('getChildren');
    return getChildren({crecheId, sectionName});
  }

  hasParent(childId: string): Observable<boolean> {
    const hasParent = this.firebaseFunctions.httpsCallable<any, boolean>('hasParent');
    return hasParent({childId});
  }

  getAffiliates(childId: string): Observable<Array<UserInfo>> {
    const getAffiliates = this.firebaseFunctions.httpsCallable<any, Array<UserInfo>>('getAffiliates');
    return getAffiliates({childId});
  }

  async updateUser(value: any): Promise<any> {
    // Load the user before updating its value
    const prevUserSnapshot = await this.firestore.doc(`users/${value.id}`).ref.get();
    const prevUser = prevUserSnapshot.data() as UserInfo;

    let updatePreviousCreche: Observable<void> = of(console.log('No creche to update'));

    if (this.isChild(prevUser)) {
      // Load it previous creche
      const previousCrecheRef = this.firestore.doc(`creches/${prevUser.creches[0]}`).ref;
      const previousCrecheSnapshot = await previousCrecheRef.get();
      const previousCreche = previousCrecheSnapshot.data() as Creche;

      if (this.crecheHasChanged(value, prevUser) || this.sectionHasChanged(value, previousCreche)) {
        previousCreche.sections.forEach(section => {
          // If user was previously in a section of its former creche
          if (this.isUserInSection(value, section)) {
            section.users = section.users.filter(userId => userId !== value.id);
            const sections = previousCreche.sections ? previousCreche.sections : [];
            updatePreviousCreche = from(previousCrecheRef.update({sections}))
              .pipe(tap(() => console.log('User removed from section of creche')));
          }
        });
      }
    }

    return updatePreviousCreche.pipe(
      concatMap(() => this.initDataAndExecute(value, (user, crecheId, sectionName) =>
        this.update(user).pipe(concatMap(() => this.addChildToSection(user, crecheId, sectionName)), take(1))
      ))
    ).toPromise();
  }

  private initDataAndExecute(value: any, exec: (user: UserInfo, crecheId: string, sectionName: string) => Observable<any>): Observable<any> {
    let crecheId;

    // Multiple creches selected (manager, staff, parent)
    if (Array.isArray(value.creches)) {
      // Replace the full Creche object by a simple list of ids
      value.creches = value.creches.map(creche => creche.id);
    } else {
      // Creche are always stored as an array
      crecheId = value.creches.id;
      value.creches = [crecheId];
    }

    // A section has been selected, the creche must be updated accordingly
    const sectionName = value.section;
    if (crecheId !== undefined && sectionName !== undefined) {
      // Erase the section property from the user, it must not be saved
      delete value.section;
    }

    // Once the taggable checkbox is unchecked, its value is null, we must set it to false
    if (value.taggable === null) {
      value.taggable = false;
    }

    return exec(value, crecheId, sectionName);
  }

  private shouldCreateAccount(value: any) {
    return !this.isChild(value);
  }

  private isChild(user: UserInfo): boolean {
    return user.role === Role.CHILD;
  }

  private addChildToSection(user: any, crecheId: string, sectionName: string): Observable<any> {
    if (this.isChild(user) && sectionName !== undefined) {
      console.log('Add child %s to section %s', user.id, sectionName);
      return this.firestore.doc(`creches/${crecheId}`).get()
        .pipe(
          map(snapshot => snapshot.data() as Creche),
          map(creche => creche.sections),
          map(sections => {
            const section = sections.find(s => s.name === sectionName);
            if (section !== undefined && section.users.indexOf(user.id) === -1) {
              console.log('Section retrieved, adding child');
              section.users.push(user.id);
              return sections;
            } else {
              console.log('Section not found or child already in it');
              return null;
            }
          }),
          concatMap((sections) => {
            if (sections === null) {
              return from(Promise.resolve());
            }
            return from(this.firestore.doc(`creches/${crecheId}`).update({sections}));
          })
        );
    }
    console.log('User %O is not a child or no section provided', user);
    return of(user);
  }

  private update(value: any): Observable<void> {
    let payload = {
      email: value.email,
      firstName: value.firstName,
      lastName: value.lastName,
      creches: value.creches,
      taggable: value.taggable
    };
    if (value.children !== undefined) {
      payload = Object.assign(payload, {children: value.children});
    }
    if (value.role === 'manager' || value.role === 'staff') {
      payload = Object.assign(payload, {role: value.role});
    }
    const updateUser = this.firebaseFunctions.httpsCallable<any, void>('updateUser');
    return updateUser({uid: value.id, payload});
  }

  private crecheHasChanged(value: any, user: UserInfo): boolean {
    return user.creches.length === 1 && user.creches[0] !== value.creches[0];
  }

  private isUserInSection(value: any, section: Section): boolean {
    return section.users.find(userId => userId === value.id) !== undefined;
  }

  private sectionHasChanged(value: any, creche: Creche): boolean {
    creche.sections.forEach(section => {
      // User was in a section
      if (this.isUserInSection(value, section)) {
        return section.name !== value.section;
      }
    });
    return false;
  }
}
