import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PublisherModel } from '../model/publisher.model';
import * as fromPublishers from '../../state/selector/publishers.selector';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ppp-admin-publishers',
  templateUrl: './publishers.component.html',
  styleUrls: ['./publishers.component.scss']
})
export class PublishersComponent {

  loading: Observable<boolean>;
  publishersDatasource: MatTableDataSource<PublisherModel>;
  publisherColumns: string[] = ['name', 'nbrPublished'];

  constructor(store: Store) {
    this.publishersDatasource = new MatTableDataSource();

    this.loading = store.select(fromPublishers.selectPublishersLoading);

    store.select(fromPublishers.selectPublishers).subscribe(
      publishers => this.publishersDatasource.data = publishers
    );
  }
}
