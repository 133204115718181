import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, USE_EMULATOR } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireFunctions, AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AdminGuard } from './admin.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CrecheCreationComponent } from './creches/creche-creation/creche-creation.component';
import { CrecheFormComponent } from './creches/creche-form/creche-form.component';
import { CrecheSectionComponent } from './creches/creche-form/creche-section.component';
import { CrecheModificationComponent } from './creches/creche-modification/creche-modification.component';
import { CrechesComponent } from './creches/creches.component';
import { DeleteCrecheDialogComponent } from './creches/delete-creche-dialog/delete-creche-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { UserCreationComponent } from './users/user-creation/user-creation.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { UserFormComponent } from './users/user-form/user-form.component';
import { UserModificationComponent } from './users/user-modification/user-modification.component';
import { UsersComponent } from './users/users.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { CrecheColumnMetadataComponent } from './creches/creche-column-metadata/creche-column-metadata.component';
import { StoragePipe } from './pipes/storage.pipe';
import { HasAnyRoleDirective } from './shared/directives/has-any-role.directive';
import { LoadingFieldComponent } from './shared/loading-field/loading-field.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChildrenCreationComponent } from './users/children-creation/children-creation.component';
import { ChildrenComponent } from './users/children/children.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersCounterComponent } from './shared/users-counter/users-counter.component';
import { ClientsCounterComponent } from './shared/clients-counter/clients-counter.component';
import { PhotosCounterComponent } from './shared/photos-counter/photos-counter.component';
import { PublicationsCounterComponent } from './shared/publications-counter/publications-counter.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CrecheEffect } from './state/effect/creche.effect';
import { countersReducer } from './state/reducer/counters.reducer';
import { PhotoEffect } from './state/effect/photo.effect';
import { PublishersComponent } from './shared/publishers/publishers.component';
import { StaffNeverConnectedComponent } from './shared/staff-never-connected/staff-never-connected.component';
import { ParentsNeverConnectedComponent } from './shared/parents-never-connected/parents-never-connected.component';
import { SectionsComponent } from './dashboard/sections/sections.component';
import { TaggedChildrenEffect } from './state/effect/tagged-children.effect';
import { CountersSelector } from './state/counter.state';
import { ParentDetailsComponent } from './users/parent-details/parent-details.component';

@NgModule({
  declarations: [
    AppComponent,
    DeleteCrecheDialogComponent,
    LoginComponent,
    UsersComponent,
    CrechesComponent,
    UserDetailsComponent,
    UserCreationComponent,
    CrecheCreationComponent,
    UserFormComponent,
    UserModificationComponent,
    HeaderComponent,
    CrecheModificationComponent,
    CrecheFormComponent,
    CrecheSectionComponent,
    ConfirmDialogComponent,
    CrecheColumnMetadataComponent,
    StoragePipe,
    HasAnyRoleDirective,
    LoadingFieldComponent,
    ChildrenCreationComponent,
    ChildrenComponent,
    DashboardComponent,
    UsersCounterComponent,
    ClientsCounterComponent,
    PhotosCounterComponent,
    PublicationsCounterComponent,
    PublishersComponent,
    StaffNeverConnectedComponent,
    ParentsNeverConnectedComponent,
    SectionsComponent,
    ParentDetailsComponent
  ],
  imports: [
    AngularFireAuthGuardModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableExporterModule,
    MatTableModule,
    MatToolbarModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    StoreModule.forRoot({counters: countersReducer}),
    EffectsModule.forRoot([CrecheEffect, PhotoEffect, TaggedChildrenEffect])
  ],
  providers: [
    AdminGuard,
    CountersSelector,
    { provide: REGION, useValue: 'europe-west1' },
    {
      provide: SETTINGS,
      useValue: environment.production ? undefined : {
        host: 'localhost:8080',
        ssl: false
      }
    },
    {
      provide: USE_EMULATOR,
      useValue: environment.production ? undefined : ['localhost', 9099]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private fireFunctions: AngularFireFunctions) {
    if (!environment.production) {
      this.fireFunctions.useFunctionsEmulator('http://localhost:5001');
    }
  }
}
