<mat-card id="users-card">
  <mat-card-title>Users</mat-card-title>
  <mat-card-content>
    <mat-spinner *ngIf="(loading | async); else users"></mat-spinner>
  </mat-card-content>
  <ng-template #users>
    <div><h2>{{usersCountersModel?.nbrOfManager}}</h2></div>
    <div><h2>{{usersCountersModel?.nbrOfStaff}}</h2></div>
    <div>Managers</div>
    <div>Staff</div>
  </ng-template>
</mat-card>
