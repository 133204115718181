import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CrecheService } from '../../shared/creche.service';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CrecheModel } from '../../../../../functions/src/models/creches/creche.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Section } from '../../../../../functions/src/models/section';
import { UserService } from '../../shared/user.service';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-ppp-admin-children-creation',
  templateUrl: './children-creation.component.html',
  styleUrls: ['./children-creation.component.scss']
})
export class ChildrenCreationComponent implements OnInit, OnDestroy, AfterViewInit {

  loading = true;
  childrenForm: FormGroup;
  @ViewChild('childrenFormContainer') childrenFormContainer: ElementRef;
  @ViewChild('crecheDropdown', {static: false}) crecheDropdown: MatSelect;
  @ViewChildren('formRow') formRows: QueryList<ElementRef>;
  @ViewChildren('add') addButtons: QueryList<MatButton>;

  filteredCreches: ReplaySubject<CrecheModel[]> = new ReplaySubject<CrecheModel[]>(1);
  crecheFilterCtrl: FormControl = new FormControl();

  filteredSections: ReplaySubject<Section[]> = new ReplaySubject<Section[]>(1);
  sectionFilterCtrl: FormControl = new FormControl();
  sections: Array<Section> = [];

  private creches: Array<CrecheModel> = [];
  private crechesSubscription: Subscription;
  private onDestroy = new Subject<void>();
  private focusRegistered = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private snackBar: MatSnackBar,
              private crecheService: CrecheService,
              private userService: UserService) {
  }

  ngOnInit() {
    const controlsConfig = this.createFormControlsConfig();
    this.childrenForm = this.formBuilder.group(controlsConfig);

    this.loadCreches();

    this.childrenForm.get('section').valueChanges.subscribe(value => {
      if (!this.focusRegistered && this.childrenForm.get('section').valid) {
        this.registerFocusOut();
      }
    });

    this.crecheFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterCreches());

    this.sectionFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterSections());
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.crechesSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.crecheDropdown.focus();
  }

  registerFocusOut() {
    setTimeout(() => {
      this.childrenFormContainer.nativeElement.addEventListener('focusout', (event) => {
        const nbrOfChildren = this.children.value.length;

        if (event.relatedTarget === undefined) {
          this.removeChildFormControl(nbrOfChildren);
        }

        const valid = this.children.controls[nbrOfChildren - 1].valid;

        // The focus is lost from the lastname input
        if (event.target.classList.contains('lastname')) {

          if (!valid && nbrOfChildren > 1) {
            this.removeChildFormControl(nbrOfChildren);
          }

          if (event.relatedTarget?.classList.contains('cancel-button')) {
            return;
          }

          // Add control only if the last one is valid
          if (valid && this.isFocusNotOnFirstnameOrLastname(event)) {
            this.addChildFormControl(nbrOfChildren);
            return;
          }
        }

        if (!valid && this.isFocusNotOnLastnameOrAdd(event)) {
          this.removeChildFormControl(nbrOfChildren);
        }
      });
    });

    this.focusRegistered = true;
  }

  get children(): FormArray {
    return this.childrenForm.get('children') as FormArray;
  }

  getSections(crecheId: string) {
    this.sections = this.creches.filter(creche => creche.id === crecheId).shift().sections;
    this.filteredSections.next(this.sections.slice());
  }

  createChildren() {
    this.loading = true;
    this.userService.createChildren(this.childrenForm.value).subscribe(
      () => {
        this.snackBar.open('Children created', null, { duration: 2000 });
        this.router.navigate(['users']);
      },
      error => {
        console.log('Something went wrong while creating users', error);
        this.snackBar.open('A problem occurred', null, { duration: 2000, panelClass: 'app-warning' });
        this.router.navigate(['users']);
      });
  }

  addRow() {
    const nbrOfChildren = this.children.value.length;
    this.addChildFormControl(nbrOfChildren);
  }

  private isFocusNotOnFirstnameOrLastname(event: any): boolean {
    return !(event.relatedTarget === undefined ||
      event.relatedTarget === null ||
      event.relatedTarget?.classList.contains('firstname') ||
      event.relatedTarget?.classList.contains('lastname'));
  }

  private isFocusNotOnLastnameOrAdd(event: any): boolean {
    return !(event.relatedTarget?.classList.contains('lastname') || event.target?.classList.contains('add-child'));
  }

  private hideAddbutton(index: number) {
    this.addButtons.find((item, idx) => idx === index)._elementRef.nativeElement.classList.add('hidden');
  }

  private showAddbutton(index: number) {
    if (index > -1) {
      this.addButtons.find((item, idx) => idx === index)._elementRef.nativeElement.classList.remove('hidden');
    }
  }

  private createFormControlsConfig(): any {
    return {
      creche: ['', Validators.required],
      section: ['', Validators.required],
      children: this.formBuilder.array([this.formBuilder.group(this.createChildrenConfig())])
    };
  }

  private createChildrenConfig(): any {
    return {
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      taggable: ['']
    };
  }

  private loadCreches() {
    this.crechesSubscription = this.crecheService.getCreches(false)
      .pipe(tap(() => this.loading = false))
      .subscribe(creches => {
        this.creches = creches;
        this.filteredCreches.next(creches.slice());
      });
  }

  private removeChildFormControl(nbrOfChildren: number) {
    if (nbrOfChildren <= 1) {
      return;
    }
    this.showAddbutton(nbrOfChildren - 2);
    this.children.removeAt(nbrOfChildren - 1);
  }

  private addChildFormControl(nbrOfChildren: number) {
    this.hideAddbutton(nbrOfChildren - 1);
    this.children.push(this.formBuilder.group(this.createChildrenConfig()));
    setTimeout(() => this.formRows.last.nativeElement.querySelector('.firstname').focus());
  }

  /**
   * Filter the list of <code>filteredCreches</code> based on user's input
   */
  private filterCreches() {
    if (!this.creches) {
      return;
    }
    // get the search keyword
    let search = this.crecheFilterCtrl.value;
    if (!search) {
      this.filteredCreches.next(this.creches.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the creches
    this.filteredCreches.next(
      this.creches.filter(creche => creche.name.toLowerCase().indexOf(search) > -1)
    );
  }

  /**
   * Filter the list of <code>filteredSection</code> based on user's input
   */
  private filterSections() {
    if (!this.sections) {
      return;
    }
    // get the search keyword
    let search = this.sectionFilterCtrl.value;
    if (!search) {
      this.filteredSections.next(this.sections.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the creches
    this.filteredSections.next(
      this.sections.filter(section => section.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
