import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UserFormComponent, UserFormMode } from '../user-form/user-form.component';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Role } from '../../../../../functions/src/models/role.enum';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-ppp-admin-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.scss']
})
export class UserCreationComponent implements AfterViewInit {

  @ViewChild(UserFormComponent) userForm: UserFormComponent;
  createMode = UserFormMode.CREATE;
  valid: boolean;
  loading: boolean;
  preloading = true;
  role: Role;
  private value: any;

  constructor(private router: Router,
              private userService: UserService,
              private snackBar: MatSnackBar) {
    this.role = router.getCurrentNavigation().extras.state.role as Role;
  }

  ngAfterViewInit() {
    this.userForm.valid.subscribe(valid => this.valid = valid);
    this.userForm.value.subscribe(value => this.value = value);
    this.userForm.loading.subscribe(preloading => this.preloading = preloading);
  }

  createUser() {
    if (!this.valid) {
      return;
    }

    this.loading = true;
    this.userService.createUser(this.value)
      .pipe(
        tap(() => this.loading = false)
      )
      .subscribe(
      response => {
        if (response?.code === 'already-exists' && response?.httpErrorCode?.status === 409) {
          this.snackBar.open('User not created, email is already used', null, { duration: 2000, panelClass: 'app-warning' });
        } else {
          this.snackBar.open('User created', null, { duration: 2000 });
        }
        this.router.navigate(['users']);
      },
      (err) => console.error('Couldn\'t create user:', err)
    );
  }
}
