<mat-card class="user-details" *appHasAnyRole="['admin']">
  <mat-card-content>
    <mat-card-content>
      <mat-spinner *ngIf="loading; else detailsContent"></mat-spinner>
    </mat-card-content>
  </mat-card-content>
  <ng-template #detailsContent>
    <div class="row" *ngIf="details">
      <div class="left">UID</div>
      <div class="right">{{details.uid}}</div>

      <div class="left">Claims</div>
      <div class="right">{{getClaims(details.customClaims)}}</div>

      <div class="left">Disabled</div>
      <div class="right">{{details.disabled}}</div>

      <div class="left">Creation time</div>
      <div class="right">{{details.metadata.creationTime}}</div>

      <div class="left">Last sign in time</div>
      <div class="right">{{details.metadata.lastSignInTime}}</div>
    </div>
  </ng-template>
</mat-card>
