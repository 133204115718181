<mat-card id="publishers" *ngIf="(loading | async) || publishersDatasource.data.length > 0">
  <mat-card-title>Publishers</mat-card-title>
  <mat-card-content>
    <mat-spinner *ngIf="(loading | async); else publishers"></mat-spinner>
  </mat-card-content>
  <ng-template #publishers>
    <table mat-table [dataSource]="publishersDatasource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="nbrPublished">
        <th mat-header-cell *matHeaderCellDef>Nbr published</th>
        <td mat-cell *matCellDef="let element">{{element.nbrPublished}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="publisherColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: publisherColumns;">
    </table>
  </ng-template>
</mat-card>
