import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Creche } from '../../../../functions/src/models/creche';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../shared/auth.service';
import { Subscription } from 'rxjs';
import { AuthStatus } from '../shared/auth.status';
import { CrecheService } from '../shared/creche.service';
import { CrecheModel } from '../../../../functions/src/models/creches/creche.model';

@Component({
  selector: 'app-ppp-admin-creches',
  templateUrl: './creches.component.html',
  styleUrls: ['./creches.component.scss']
})
export class CrechesComponent implements OnInit, OnDestroy {

  loading = true;
  displayedColumns: string[] = [];
  creches: Array<CrecheModel> = [];
  dataSource = new MatTableDataSource<Creche>(this.creches);
  private columns: string[] = ['name', 'address', 'parents', 'staff', 'sections', 'photos'];
  private crecheSubscription: Subscription;

  constructor(private router: Router,
              private crecheService: CrecheService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.crecheSubscription = this.crecheService.getCreches()
      .subscribe(creches => {
        this.loading = false;
        this.creches = creches;
        this.dataSource.data = this.creches;
      });

    this.authService.isLoggedIn.subscribe((status: AuthStatus) => {
      if (status.unauthenticated) {
        // Firebase keep track of changes with snapshotChanges, unsubscribe prevents "Missing or insufficient permissions" error
        this.crecheSubscription.unsubscribe();
      }
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.setDisplayedColumns();
  }

  ngOnDestroy(): void {
    this.crecheSubscription.unsubscribe();
  }

  goToCrecheCreation() {
    this.router.navigate(['creches/create']);
  }

  private setDisplayedColumns() {
    this.displayedColumns = this.columns.slice(0, window.innerWidth < 768 ? 1 : this.columns.length);
  }
}
