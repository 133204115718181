import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfo } from '../../../../../functions/src/models/user-info';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromParents from '../../state/selector/parents.selector';
import * as fromClients from '../../state/selector/clients-counters.selector';

@Component({
  selector: 'app-admin-parents-never-connected',
  templateUrl: './parents-never-connected.component.html',
  styleUrls: ['./parents-never-connected.component.scss']
})
export class ParentsNeverConnectedComponent implements AfterViewInit {

  loading: Observable<boolean>;
  loadingClients: Observable<boolean>;
  parentsDatasource: MatTableDataSource<UserInfo>;
  parentsColumns: string[] = [];
  nbrOfParents: number;

  @ViewChild(MatSort) sort: MatSort;

  constructor(store: Store) {
    this.parentsDatasource = new MatTableDataSource();
    this.setDisplayedColumns();

    this.loading = store.select(fromParents.selectParentsLoading);
    this.loadingClients = store.select(fromClients.selectClientsCountersLoading);

    store.select(fromParents.selectParents).subscribe(
      parents => this.parentsDatasource.data = parents
    );
    store.select(fromClients.selectClientsCounters).subscribe(
      counter => this.nbrOfParents = counter?.nbrOfParents
    );
  }

  ngAfterViewInit() {
    this.parentsDatasource.sort = this.sort;
  }

  @HostListener('window:resize')
  onResize() {
    this.setDisplayedColumns();
  }

  private setDisplayedColumns() {
    if (window.innerWidth < 660) {
      this.parentsColumns = ['firstName', 'lastName'];
    } else {
      this.parentsColumns = ['firstName', 'lastName', 'email'];
    }
  }
}
