<mat-toolbar color="secondary">
  <h1>Modify user</h1>
</mat-toolbar>

<div class="container" *ngIf="!loading">
  <mat-card class="user-card">
    <mat-card-content>
      <app-ppp-admin-user-form [mode]="updateMode" [user]="user" [role]="user?.role"></app-ppp-admin-user-form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="updateUser()" [disabled]="!valid || preloading" type="submit">Update</button>
      <button mat-button (click)="goBack()">Cancel</button>
      <button mat-button color="warn" (click)="deleteUser()">Delete</button>
    </mat-card-actions>
  </mat-card>

  <app-ppp-admin-parent-details [user]="user" *ngIf="user?.role === 'child'"></app-ppp-admin-parent-details>

  <div *appHasAnyRole="['admin']">
    <app-ppp-admin-user-details *ngIf="user.role != 'child'" [uid]="user?.id"></app-ppp-admin-user-details>
  </div>
</div>

<div class="spinner-container" *ngIf="loading">
  <mat-spinner class="spinner"></mat-spinner>
</div>
