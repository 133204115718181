import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UsersCountersModel } from '../model/users-counters.model';
import * as fromUsersCounters from '../../state/selector/users-counters.selector';

@Component({
  selector: 'app-ppp-admin-users-counter',
  templateUrl: './users-counter.component.html',
  styleUrls: ['./users-counter.component.scss']
})
export class UsersCounterComponent {

  loading: Observable<boolean>;
  usersCountersModel: UsersCountersModel;

  constructor(store: Store) {
    this.loading = store.select(fromUsersCounters.selectUsersCountersLoading);
    store.select(fromUsersCounters.selectUsersCounters).subscribe(
      usersCountersModel => this.usersCountersModel = usersCountersModel
    );
  }

}
