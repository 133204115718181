import { createSelector } from '@ngrx/store';
import { CountersState, selectCountersState, StaffNeverConnectedState } from '../counter.state';

export const selectStaffState = createSelector(
  selectCountersState,
  (state: CountersState) => state.staffState
);

export const selectStaffLoading = createSelector(
  selectStaffState,
  (state: StaffNeverConnectedState) => state?.loading
);

export const selectStaff = createSelector(
  selectStaffState,
  (state: StaffNeverConnectedState) => state?.staff
);
