import { createSelector } from '@ngrx/store';
import { CountersState, selectCountersState, UsersCountersState } from '../counter.state';

export const selectUsersCountersState = createSelector(
  selectCountersState,
  (state: CountersState) => state.usersCountersState
);

export const selectUsersCountersLoading = createSelector(
  selectUsersCountersState,
  (state: UsersCountersState) => state?.loading
);

export const selectUsersCounters = createSelector(
  selectUsersCountersState,
  (state: UsersCountersState) => state?.usersCounters
);
