import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ppp-admin-loading-field',
  templateUrl: './loading-field.component.html',
  styleUrls: ['./loading-field.component.scss']
})
export class LoadingFieldComponent {

  @Input()
  loading: boolean;

  @Input()
  message: string;

  constructor() { }

}
