import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserInfo } from '../../../../functions/src/models/user-info';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { AuthStatus } from '../shared/auth.status';
import { PaginationDatasoure } from '../shared/pagination.datasoure';
import { UserService } from '../shared/user.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CrecheService } from '../shared/creche.service';
import { LocalStorageService } from '../shared/local-storage.service';
import { CrecheModel } from '../../../../functions/src/models/creches/creche.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ppp-admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {

  loading = true;
  pageSize = 500;
  columns: string[] = ['firstName', 'lastName', 'email', 'creches', 'role', 'nanoid', 'termsAcceptationDate', 'taggable'];
  users: Array<UserInfo> = [];
  filteredCreches: ReplaySubject<CrecheModel[]> = new ReplaySubject<CrecheModel[]>(1);
  crecheForm: FormGroup;
  crecheFilterCtrl: FormControl = new FormControl();
  creches: Array<CrecheModel> = [];
  crecheId: string;
  initialSort: Sort = {active: 'firstName', direction: 'asc'};
  dataSource = new PaginationDatasoure<UserInfo>(
    request => this.userService.getUsers(request),
    this.initialSort,
    this.pageSize
  );
  isAdmin = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  private subscription: Subscription;
  private onDestroy = new Subject<void>();

  constructor(private userService: UserService,
              private crecheService: CrecheService,
              private firestore: AngularFirestore,
              private router: Router,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private localStorage: LocalStorageService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.crecheForm = this.formBuilder.group({
      creche: ['']
    });
    this.crecheFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterCreches());
    this.filterByCreche(this.localStorage.getOrDefault('crecheId'));

    this.subscription = this.crecheService.getCreches(false).subscribe(creches => {
      this.filteredCreches.next(creches.slice());
      this.creches = creches;
    });
    this.dataSource.loadingStatus.subscribe(loading => {
      this.loading = loading;
    });
    this.authService.isLoggedIn.subscribe((status: AuthStatus) => {
      this.isAdmin = status.role === 'admin';
      if (status.unauthenticated) {
        // Firebase keep track of changes with snapshotChanges, unsubscribe prevents "Missing or insufficient permissions" error
        this.subscription.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit() {
    this.dataSource.matSort = this.matSort;
  }

  goToUserCreation(role: string = null) {
    this.router.navigate(['users/create'], { state: {role} });
  }

  goToChildrenCreation() {
    this.router.navigate(['users/create-children']);
  }

  goToChildren() {
    this.router.navigate(['users/children']);
  }

  editUser(user: UserInfo) {
    this.router.navigate(['users', user.id, 'update'], { state: user });
  }

  filterByCreche(crecheId: string) {
    if (this.crecheId === crecheId) {
      return;
    }

    this.crecheId = crecheId;
    if (crecheId === undefined || crecheId === '--' || crecheId === '') {
      crecheId = undefined;
      this.localStorage.remove('crecheId');
    } else {
      this.localStorage.set('crecheId', crecheId);
    }

    this.dataSource.filterBy(crecheId);

    const queryParams: Params = { crecheId };
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      });
  }

  pageChange(page: PageEvent) {
    if (page.pageSize !== this.pageSize) {
      this.pageSize = page.pageSize;
      if (page.pageIndex === 0) {
        this.dataSource.fetch(page);
      } else {
        this.paginator.firstPage();
      }
    } else {
      this.dataSource.fetch(page);
    }
  }

  selectCell(event: any) {
    event.stopPropagation();
  }

  copyToClipboard(event: any, value: string) {
    event.stopPropagation();
    navigator.clipboard.writeText(value).then(
      () => this.snackBar.open(`${value} copied to clipboard`, null, { duration: 2000 })
    );
  }

  /**
   * Filter the list of <code>filteredCreches</code> based on user's input
   */
  private filterCreches() {
    if (!this.creches) {
      return;
    }
    // get the search keyword
    let search = this.crecheFilterCtrl.value;
    if (!search) {
      this.filteredCreches.next(this.creches.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the creches
    this.filteredCreches.next(
      this.creches.filter(creche => creche.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
