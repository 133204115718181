import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthStatus } from './shared/auth.status';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(private auth: AngularFireAuth, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.authState.pipe(
      take(1),
      switchMap(async (authState) => {
        if (!authState) {
          console.debug('USER NOT LOGGED IN');
          return this.router.navigate(['login']);
        }
        const token = await authState.getIdTokenResult();
        console.debug('Token:', token);

        const authStatus = AuthStatus.of(token);
        if (route.data !== undefined && route.data?.roles?.indexOf(authStatus.role) === -1) {
          return this.router.navigate(['dashboard']);
        }

        if (authStatus.authenticated && authStatus.authorized) {
          return true;
        }
        console.debug('USER NOT ADMIN');
        return this.router.navigate(['login']);
      })
    );
  }
}
