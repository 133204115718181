import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CrecheModel } from '../../../../functions/src/models/creches/creche.model';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { CrecheService } from '../shared/creche.service';
import { MatSelect } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { PhotosActions, PhotosMetadataActions, UsersActions } from '../state/action';
import { DeleteCrecheDialogComponent } from '../creches/delete-creche-dialog/delete-creche-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ppp-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  loading = true;
  creche: CrecheModel;
  crecheId: string;
  filteredCreches: ReplaySubject<CrecheModel[]> = new ReplaySubject<CrecheModel[]>(1);
  crecheForm: FormGroup;
  crecheFilterCtrl: FormControl = new FormControl();
  creches: Array<CrecheModel> = [];

  @ViewChild('crecheDropdown', {static: false}) crecheDropdown: MatSelect;

  private subscription: Subscription;
  private onDestroy = new Subject<void>();

  constructor(private store: Store,
              private router: Router,
              private formBuilder: FormBuilder,
              private crecheService: CrecheService,
              private firestore: AngularFirestore,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.crecheForm = this.formBuilder.group({
      creche: ['', Validators.required]
    });
    this.crecheFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.filterCreches());

    this.subscription = this.crecheService.getCreches(false).subscribe(
      creches => {
        this.loading = false;
        this.creches = creches;
        this.filteredCreches.next(creches.slice());
        this.crecheId = creches[0].id;
        this.crecheForm.get('creche').setValue(this.crecheId);
        this.filterByCreche(this.crecheId);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  filterByCreche(crecheId: string) {
    this.creche = this.creches.find(creche => creche.id === crecheId);
    this.store.dispatch(UsersActions.loadUsersCounters({ crecheId }));
    this.store.dispatch(PhotosMetadataActions.loadPhotosMetadata({ creche: this.creche }));
    this.store.dispatch(PhotosActions.loadPhotos({ photosIds: this.creche.photos }));
  }

  deleteCreche() {
    const dialogRef = this.dialog.open(DeleteCrecheDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.firestore.collection('creches').doc(this.creche.id).delete().then(() => {
        console.log('Creche deleted!');
        this.router.navigate(['creches']);
      }).catch(error => {
        console.log(error);
      });
    });
  }

  /**
   * Filter the list of <code>filteredCreches</code> based on user's input
   */
  private filterCreches() {
    if (!this.creches) {
      return;
    }
    // get the search keyword
    let search = this.crecheFilterCtrl.value;
    if (!search) {
      this.filteredCreches.next(this.creches.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the creches
    this.filteredCreches.next(
      this.creches.filter(creche => creche.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
