<mat-toolbar color="secondary">
  <mat-toolbar-row>
    <h1>Creches</h1>
    <button *appHasAnyRole="['admin']" mat-raised-button color="primary" (click)="goToCrecheCreation()" class="create-creche__button">Create creche</button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="spinner-container" *ngIf="loading">
  <mat-spinner class="spinner"></mat-spinner>
</div>

<div class="container" [ngClass]="{'hidden':loading}">
  <div class="mat-elevation-z4">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let element">{{element.address}}, {{element.zipCode}} {{element.city}}</td>
      </ng-container>

      <ng-container matColumnDef="parents">
        <th mat-header-cell *matHeaderCellDef>Nbr parent</th>
        <td mat-cell *matCellDef="let element">{{element.usersMetadata.nbrOfParent}}</td>
      </ng-container>

      <ng-container matColumnDef="staff">
        <th mat-header-cell *matHeaderCellDef>Nbr staff</th>
        <td mat-cell *matCellDef="let element">{{element.usersMetadata.nbrOfStaff}}</td>
      </ng-container>

      <ng-container matColumnDef="sections">
        <th mat-header-cell *matHeaderCellDef>Sections</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let section of element.sections">
            {{section.name}} ({{section.users.length}} children)
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="photos">
        <th mat-header-cell *matHeaderCellDef>Photos</th>
        <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <app-ppp-admin-creche-column-metadata [creche]="element"></app-ppp-admin-creche-column-metadata>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </table>
  </div>
</div>


