import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PhotosActions } from '../action';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PhotoService } from '../../shared/photo.service';

@Injectable()
export class PhotoEffect {

  loadPhotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PhotosActions.loadPhotos),
      exhaustMap(action =>
        this.photoService.getPhotos(action.photosIds).pipe(
          map(photos => PhotosActions.photosLoaded({ photos })),
          catchError(error => of(PhotosActions.errorLoadingPhotos({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions,
              private photoService: PhotoService) {
  }
}
