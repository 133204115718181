import { createSelector } from '@ngrx/store';
import { CountersState, ParentsNeverConnectedState, selectCountersState } from '../counter.state';

export const selectParentsState = createSelector(
  selectCountersState,
  (state: CountersState) => state.parentsState
);

export const selectParentsLoading = createSelector(
  selectParentsState,
  (state: ParentsNeverConnectedState) => state?.loading
);

export const selectParents = createSelector(
  selectParentsState,
  (state: ParentsNeverConnectedState) => state?.parents
);
