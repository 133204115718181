import { createAction, props } from '@ngrx/store';
import { CrecheUsersMetadataModel } from '../../../../../functions/src/models/creches/creche-users-metadata.model';

export enum UsersActionsTypes {
  LOAD_USERS_COUNTERS = '[USER] Load users counters',
  USERS_COUNTERS_LOADED = '[USER] Users counters loaded',
  ERROR_LOADING_USERS_COUNTERS = '[USER] Error loading users counters'
}

export const loadUsersCounters = createAction(
  UsersActionsTypes.LOAD_USERS_COUNTERS,
  props<{crecheId: string}>()
);

export const usersCountersLoaded = createAction(
  UsersActionsTypes.USERS_COUNTERS_LOADED,
  props<{ metamodel: CrecheUsersMetadataModel }>()
);

export const errorLoadingUsersCounters = createAction(
  UsersActionsTypes.ERROR_LOADING_USERS_COUNTERS,
  props<{ error: any }>()
);
