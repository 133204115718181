import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { PublicationsCountersModel } from '../model/publications-counters.model';
import { Store } from '@ngrx/store';
import * as fromPublications from '../../state/selector/publications.selector';

@Component({
  selector: 'app-ppp-admin-publications-counter',
  templateUrl: './publications-counter.component.html',
  styleUrls: ['./publications-counter.component.scss']
})
export class PublicationsCounterComponent {

  loading: Observable<boolean>;
  publicationsCountersModel: PublicationsCountersModel;

  constructor(store: Store) {
    this.loading = store.select(fromPublications.selectPublicationsLoading);
    store.select(fromPublications.selectPublications).subscribe(
      publicationsCountersModel => this.publicationsCountersModel = publicationsCountersModel
    );
  }
}
