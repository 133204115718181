<mat-card id="affiliates">
  <mat-card-title>Affiliates</mat-card-title>
  <mat-card-content>
    <mat-spinner *ngIf="loading; else publishers"></mat-spinner>
  </mat-card-content>
  <ng-template #publishers>
    <table mat-table [dataSource]="affiliatesDatasource" *ngIf="affiliatesDatasource.data.length > 0; else noAffiliate">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>Firstname</th>
        <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Lastname</th>
        <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{element.email}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="affiliatesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: affiliatesColumns;">
    </table>

  </ng-template>
</mat-card>

<ng-template #noAffiliate>
  No affiliate
</ng-template>
