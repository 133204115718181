import { createSelector } from '@ngrx/store';
import { selectPublishersLoading } from './publishers.selector';
import { selectPhotosCounters, selectPhotosCountersLoading } from './photos-counters.selector';
import { PublicationsCountersModel } from '../../shared/model/publications-counters.model';
import { CountersState, PublicationsState, selectCountersState } from '../counter.state';

export const selectPublicationsLoading = createSelector(
  selectPhotosCountersLoading,
  selectPublishersLoading,
  (photosLoading, publishersLoading) => photosLoading || publishersLoading
);

export const selectPublicationsState = createSelector(
  selectCountersState,
  (state: CountersState) => state.publicationsState
);

export const selectPublications = createSelector(
  selectPublicationsState,
  selectPhotosCounters,
  (publicationsState: PublicationsState, photosCounters) => {
    if (photosCounters !== undefined && publicationsState !== undefined) {
      const nbrOfUnpublished = photosCounters.nbrOfPhotos - publicationsState.nbrOfPublished;
      return new PublicationsCountersModel(publicationsState.nbrOfPublished, nbrOfUnpublished);
    }
    return new PublicationsCountersModel(0, 0);
  }
);
