import { createAction, props } from '@ngrx/store';
import { ChildModel } from '../../shared/model/child.model';

export enum TaggedChildrenTypes {
  CHILDREN_TAGGED = '[TAGGED CHILDREN] Children tagged',
}

export const childrenTagged = createAction(
  TaggedChildrenTypes.CHILDREN_TAGGED,
  props<{children: Array<ChildModel>}>()
);
