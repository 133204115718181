import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { PhotosCountersModel } from '../model/photos-counters.model';
import { Store } from '@ngrx/store';
import * as fromPhotosCounters from '../../state/selector/photos-counters.selector';

@Component({
  selector: 'app-ppp-admin-photos-counter',
  templateUrl: './photos-counter.component.html',
  styleUrls: ['./photos-counter.component.scss']
})
export class PhotosCounterComponent {

  loading: Observable<boolean>;
  photosCountersModel: PhotosCountersModel;

  constructor(store: Store) {
    this.loading = store.select(fromPhotosCounters.selectPhotosCountersLoading);
    store.select(fromPhotosCounters.selectPhotosCounters).subscribe(
      photosCountersModel => this.photosCountersModel = photosCountersModel
    );
  }

}
