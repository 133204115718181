import { Role } from '../../../../functions/src/models/role.enum';

export class AuthStatus {

  private readonly _userId: string | null;
  private readonly _role: Role|null;
  private readonly _error: string|null;
  private readonly _email: string|null;

  static of(token: any): AuthStatus {
    if (token === null || token === undefined) {
      return new AuthStatus(null, null, null, 'User not authenticated');
    }
    const userId = token.claims.user_id;
    const role = AuthStatus.getRole(token.claims);
    if (role === null) {
      return new AuthStatus(userId, null, null, 'Access not allowed');
    }
    return new AuthStatus(userId, role, token.claims.email);
  }

  constructor(userId: string, role: Role = null, email: string = '', error: string = '') {
    this._userId = userId;
    this._role = role;
    this._email = email;
    this._error = error;
  }

  get userId(): string {
    return this._userId;
  }

  get authenticated(): boolean {
    return this._userId !== null;
  }

  get unauthenticated(): boolean {
    return !this.authenticated;
  }

  get authorized(): boolean {
    return this._role !== null;
  }

  get role(): Role {
    return this._role;
  }

  get email(): string {
    return this._email;
  }

  get error(): string|null {
    return this._error;
  }

  private static getRole(claim: any): Role {
    if (claim.admin === true) {
      return Role.ADMIN;
    }
    if (claim.manager === true) {
      return Role.MANAGER;
    }
    if (claim.staff === true) {
      return Role.STAFF;
    }
    return null;
  }
}
