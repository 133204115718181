export declare type SortDirection = 'asc' | 'desc' | '';

export interface Sort {
  active: string;
  direction: SortDirection;
}

export class PageRequest {
  page: number;
  size: number;
  sort: Sort;
  startAt: string | null;
  startAfter: string | null;
  filter: string | null;

  constructor(page: number,
              size: number,
              sort: Sort,
              filter: string | null = null,
              startAt: string | null = null,
              startAfter: string | null = null) {
    this.page = page;
    this.size = size;
    this.sort = sort;
    this.startAt = startAt;
    this.startAfter = startAfter;
    this.filter = filter;
  }
}

export class Page<T> {
  content: T[];
  totalElements: number;
  size: number;
  index: number;
  startAt: string;
  startAfter: string;

  constructor(content: T[],
              totalElements: number,
              size: number,
              index: number,
              startAt: string,
              startAfter: string) {
    this.content = content;
    this.totalElements = totalElements;
    this.size = size;
    this.index = index;
    this.startAt = startAt;
    this.startAfter = startAfter;
  }
}
