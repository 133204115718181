import { UserInfo } from '../user-info';

export class CreateUserRequest {
  user: UserInfo;
  withAccount: boolean;

  constructor(user: UserInfo, withAccount: boolean) {
    this.user = user;
    this.withAccount = withAccount;
  }
}