import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from '../../../../../functions/src/models/user-info';
import { UserService } from '../../shared/user.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ppp-admin-parent-details',
  templateUrl: './parent-details.component.html',
  styleUrls: ['./parent-details.component.scss']
})
export class ParentDetailsComponent implements OnInit {

  loading: boolean;
  affiliatesDatasource: MatTableDataSource<UserInfo>;
  affiliatesColumns: string[] = ['firstName', 'lastName', 'email'];
  @Input() user: UserInfo;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.affiliatesDatasource = new MatTableDataSource();
    this.loading = true;
    this.userService.getAffiliates(this.user?.id).subscribe(
      affiliates => {
        this.affiliatesDatasource.data = affiliates;
        this.loading = false;
      },
      error => {
        console.log('An error occured while loading affiliates', error);
        this.loading = false;
      });
  }

}
