<mat-card id="publications">
  <mat-card-title>Publication</mat-card-title>
  <mat-card-content>
    <mat-spinner *ngIf="(loading | async); else photos"></mat-spinner>
  </mat-card-content>
  <ng-template #photos>
    <div><h2>{{publicationsCountersModel.nbrOfPublished}}</h2></div>
    <div><h2>{{publicationsCountersModel.nbrOfUnpublished}}</h2></div>
    <div>Published</div>
    <div>Unpublished</div>
  </ng-template>
</mat-card>
