import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { AuthService } from '../../shared/auth.service';
import { AuthStatus } from '../../shared/auth.status';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ppp-admin-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  @Input() uid: string;
  details: any;
  loading: boolean;

  private authSubscription: Subscription;

  constructor(private userService: UserService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.loading = true;
    this.authSubscription = this.authService.isLoggedIn.subscribe((authStatus: AuthStatus) => {
      if (authStatus.role === 'admin') {
        this.userService.getUserDetails(this.uid).subscribe(details => {
          this.details = details;
          this.loading = false;
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  getClaims(claims: object): string {
    return Object.keys(claims).map(key => {
      return '' + key + ':' + claims[key];
    }).join(', ');
  }
}
