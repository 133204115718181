import { NoOpAction, PhotosActions, PhotosMetadataActions, TaggedChildrenActions, UsersActions } from '../action';
import { createReducer, on } from '@ngrx/store';
import { UsersCountersModel } from '../../shared/model/users-counters.model';
import { ClientsCountersModel } from '../../shared/model/clients-counters.model';
import { PhotosCountersModel } from '../../shared/model/photos-counters.model';
import { PublisherModel } from '../../shared/model/publisher.model';
import { PhotoModel } from '../../shared/model/photo.model';
import { UserInfo } from '../../../../../functions/src/models/user-info';
import { CountersState } from '../counter.state';

export const initialState: CountersState = {
  metadata: undefined,
  photos: undefined,
  usersCountersState: {
    loading: true,
    usersCounters: undefined
  },
  clientsCountersState: {
    loading: true,
    clientsCounters: undefined
  },
  parentsState: {
    loading: true,
    parents: []
  },
  staffState: {
    loading: true,
    staff: []
  },
  childrenState: {
    loading: true,
    children: undefined
  },
  photosCountersState: {
    loading: true,
    photosCounters: undefined
  },
  publishersState: {
    loading: true,
    publishers: []
  },
  publicationsState: {
    nbrOfPublished: 0
  }
};

export const countersReducer = createReducer(
  initialState,
  on(UsersActions.loadUsersCounters, (state: CountersState) => ({
    ...state,
    usersCountersState: {
      loading: true,
      usersCounters: undefined
    },
    clientsCountersState: {
      loading: true,
      clientsCounters: undefined
    },
    parentsState: {
      loading: true,
      parents: []
    },
    staffState: {
      loading: true,
      staff: []
    }
  })),
  on(UsersActions.usersCountersLoaded, (state: CountersState, { metamodel }) => {
    const usersCounters = new UsersCountersModel(metamodel.nbrOfManager, metamodel.nbrOfStaff);
    const clientsCounters = new ClientsCountersModel(metamodel.nbrOfParent, metamodel.nbrOfChild);
    const parents = retainUnacceptedTerms(metamodel.parents);
    const staff = retainUnacceptedTerms(metamodel.staff);
    return {
      ...state,
      metadata: metamodel,
      usersCountersState: {
        loading: false,
        usersCounters
      },
      clientsCountersState: {
        loading: false,
        clientsCounters
      },
      parentsState: {
        loading: false,
        parents
      },
      staffState: {
        loading: false,
        staff
      }
    };
  }),
  on(PhotosMetadataActions.loadPhotosMetadata, (state: CountersState) => ({
    ...state,
    photosCountersState: {
      loading: true,
      photosCounters: undefined
    }
  })),
  on(PhotosMetadataActions.photosMetadataCountersLoaded, (state: CountersState, { photosLength, metamodel }) => {
    const nbrOfPhotos = metamodel.nbrOfPhotos;
    const nbrOfRefs = photosLength;
    const storageSize = metamodel.storageSize;
    return {
      ...state,
      photosCountersState: {
        loading: false,
        photosCounters: new PhotosCountersModel(nbrOfPhotos, nbrOfRefs, storageSize)
      }
    };
  }),
  on(PhotosActions.loadPhotos, (state: CountersState) => ({
    ...state,
    publishersState: {
      loading: true,
      publishers: []
    }
  })),
  on(PhotosActions.photosLoaded, (state: CountersState, { photos }) => {
    let nbrOfPublished = 0;
    photos.forEach(photo => {
      if (photo.publishedAt > 0) {
        nbrOfPublished++;
      }
    });
    return {
      ...state,
      photos,
      publishersState: {
        loading: false,
        publishers: computePublishers(photos)
      },
      publicationsState: {
        nbrOfPublished
      }
    };
  }),
  on(TaggedChildrenActions.childrenTagged, (state: CountersState, { children }) => {
    if (children === undefined) {
      return {
        ...state
      };
    }
    return {
      ...state,
      childrenState: {
        loading: false,
        children
      }
    };
  })
);

function computePublishers(photos: PhotoModel[]): Array<PublisherModel> {
  let publishers = new Map<string, number>();
  photos.forEach(photo => {
    if (photo.publishedAt > 0) {
      let nbr = publishers.get(photo.publisher);
      if (nbr === undefined) {
        nbr = 0;
      }
      publishers.set(photo.publisher, ++nbr);
    }
  });

  const models = [];
  if (publishers.size > 0) {
    // Sort publishers by number of photos published, descending
    publishers = new Map<string, number>([...publishers.entries()].sort((a, b) => b[1] - a[1]));
    publishers.forEach((nbrPublished, name) => models.push(new PublisherModel(name, nbrPublished)));
  }

  return models;
}

function retainUnacceptedTerms(users: Array<UserInfo>): Array<UserInfo> {
  return users.filter(user => user.termsAcceptationDate === undefined);
}
