import { createAction, props } from '@ngrx/store';
import { PhotoModel } from '../../shared/model/photo.model';

export enum PhotosActionsTypes {
  LOAD_PHOTOS = '[PHOTOS] Load photos',
  PHOTOS_LOADED = '[PHOTOS] Photos loaded',
  ERROR_LOADING_PHOTOS = '[PHOTOS] Error loading photos'
}

export const loadPhotos = createAction(
  PhotosActionsTypes.LOAD_PHOTOS,
  props<{ photosIds: Array<string> }>()
);

export const photosLoaded = createAction(
  PhotosActionsTypes.PHOTOS_LOADED,
  props<{ photos: Array<PhotoModel> }>()
);

export const errorLoadingPhotos = createAction(
  PhotosActionsTypes.ERROR_LOADING_PHOTOS,
  props<{ error: any }>()
);
