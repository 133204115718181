import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Creche } from '../../../../../functions/src/models/creche';
import { deepCopy } from '../../shared/utils';
import { AngularFirestore } from '@angular/fire/firestore';
import { Section } from '../../../../../functions/src/models/section';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { AuthService } from '../../shared/auth.service';

export enum CrecheFormMode {
  CREATE, UPDATE
}

@Component({
  selector: 'app-ppp-admin-creche-form',
  templateUrl: './creche-form.component.html',
  styleUrls: ['./creche-form.component.scss']
})
export class CrecheFormComponent implements OnInit, OnDestroy {

  crecheForm: FormGroup;
  sectionFormControl = new FormControl();
  @Input() mode: CrecheFormMode;
  @Input() creche: Creche;
  sections: Map<string, FormControl>;
  private validStatus = new ReplaySubject<boolean>(1);
  private valueStatus = new Subject<any>();
  private loggedInSubscription: Subscription;

  constructor(private firestore: AngularFirestore,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.crecheForm = this.formBuilder.group({
      name: [{value: this.creche?.name, disabled: true}, Validators.required],
      address: [{value: this.creche?.address, disabled: true}, Validators.required],
      city: [{value: this.creche?.city, disabled: true}, Validators.required],
      zipCode: [{value: this.creche?.zipCode, disabled: true}, Validators.required]
    });

    this.sections = new Map();
    if (this.creche?.sections?.length > 0) {
      this.creche.sections.map((section: Section) => {
        this.registerSectionControl(section.name);
      });
    }

    this.crecheForm.valueChanges.subscribe(val => {
      this.validStatus.next(this.crecheForm.valid);
      this.valueStatus.next(deepCopy(val));
    });

    if (this.mode === CrecheFormMode.UPDATE) {
      this.crecheForm.markAllAsTouched();
    }

    this.loggedInSubscription = this.authService.isLoggedIn.subscribe(authStatus => {
      if (authStatus.role === 'admin') {
        this.crecheForm.controls.name.enable();
        this.crecheForm.controls.address.enable();
        this.crecheForm.controls.city.enable();
        this.crecheForm.controls.zipCode.enable();
      }
    });
  }

  ngOnDestroy() {
    this.loggedInSubscription?.unsubscribe();
  }

  @Output()
  get valid(): Observable<boolean> {
    return this.validStatus.asObservable();
  }

  @Output()
  get value(): Observable<any> {
    return this.valueStatus.asObservable();
  }

  addSection() {
    this.registerSectionControl(this.sectionFormControl.value);
    this.sectionFormControl.setValue(null);
  }

  removeSection(controlName) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.crecheForm.removeControl(controlName);
        this.sections.delete(controlName);
      }
    });
  }

  private registerSectionControl(sectionName: string) {
    const key = 'section_' + (this.sections.size + 1);
    const control = new FormControl(sectionName, Validators.required);
    this.crecheForm.addControl(key, control);
    this.sections.set(key, control);
  }
}
